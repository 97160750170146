export default {
  de: {
    0: 'keine',
    1: 'leichte Verfehlung',
    2: 'mittlere Verfehlung',
    3: 'Schwere Vefehlung'
  },
  fr: {
    0: 'aucune',
    1: 'faute légère',
    2: 'faute moyenne',
    3: 'faute grave'
  },
  it: {
    0: 'nessuna',
    1: 'violazione leggera',
    2: 'violazione media',
    3: 'violazione grave'
  }
}
