import {html} from '@isceco/widget-library2/external/lit'
import SelectCell from '@isceco/widget-library2/basic-elements/List/cell/SelectCell.js'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import Dossierstatus from './Dossierstatus.js'

export default class StatusCell extends SelectCell {
  constructor(configuration) {
    super(configuration)
    this.archivedText = configuration.archivedText
  }

  render(i18n, cell, row) {
    return html`
      ${super.render(i18n, cell)}
      ${this.getInfoButton(i18n, cell, row)}
    `
  }

  getInfoText(i18n, cell, row) {
    const text = []
    if (!isEmpty(row.zurueckgewiesen_grund)) {
      text.push(row.zurueckgewiesen_grund)
    }
    if (cell === Dossierstatus.STATUS_KEYS.ARCHIVIERT) {
      text.push(i18n.translate(this.archivedText))
    }
    return text.join('<hr>')
  }

  getInfoButton(i18n, cell, row) {
    const infoText = this.getInfoText(i18n, cell, row)
    if (!isEmpty(infoText)) {
      return html`
        <isceco-popup direction="up">
          <isceco-button size="small"
                         icon="info"
                         slot="wrapper"
          ></isceco-button>
          <div slot="content">${infoText}</div>
        </isceco-popup>
      `
    }
    return html``
  }
}
