import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import MetadatenService from '../MetadatenService.js'
import DetenfileDetailCss from './DatenfileDetailCss.js'
import DossierRender from './Render/DossierRender.js'
import CompanyRender from './Render/CompanyRender.js'
import EmployeeRender from './Render/EmployeeRender'
import PBKRender from './Render/PBKRender'
import DossierService from '../../Dossier/DossierService'
import MetadatenDto from './MetadatenDto'
import OrganisationService from '../../Organisation/OrganisationService'

export default class DatenfileDetail extends WebComponent {
  static get PARAM() {
    return {
      ID: 'id',
      DOSSIER: 'dossier'
    }
  }

  constructor() {
    super()
  }

  get translationFile() {
    return './views/Datenfile/Detail/i18n.json'
  }

  get css() {
    return DetenfileDetailCss
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
  }

  reload() {
    const params = getNavigationParams()
    this.read = params.get('edit') !== 'true'
    if (params.has(DatenfileDetail.PARAM.ID)) {
      this.loadDataFromId(params.get(DatenfileDetail.PARAM.ID))
    } else if (params.has(DatenfileDetail.PARAM.DOSSIER)) {
      this.loadDataFromDossier(params.get(DatenfileDetail.PARAM.DOSSIER))
    } else {
      this.newData()
    }
  }

  loadDataFromId = id => {
    const metadatenService = new MetadatenService()
    metadatenService.read(id)
      .then(json => {
        this.metadaten = new MetadatenDto(json)
        this.render()
      })
      .catch(_ => {
        this.newData()
      })
  }

  loadDataFromDossier = dossierCode => {
    const dossierService = new DossierService()
    Promise.all([dossierService.read(dossierCode, 'metadaten'), dossierService.read(dossierCode)])
      .then(([response, dossier]) => {
        response.blob()
          .then(blob => blob.text())
          .then(json => {
            this.metadaten = new MetadatenDto({
              changed: dossier.changed,
              name: shortenFilename(dossier.metadaten).replace('.json', ''),
              organisation: dossier.sender.code,
              valid: true,
              art: dossier.art,
              createdBy: window.keycloak.tokenParsed.name,
              content: json
            })
            this.render()
          })
      })
      .catch(_ => {
        this.newData()
      })
  }

  newData = () => {
    this.metadaten = new MetadatenDto({})
    this.render()
  }

  getTemplate() {
    let title
    let description = ''
    if (this.read) {
      title = 'datenfile.titel.read'
    } else {
      title = 'datenfile.titel.write'
      description = html`
        <p>${this.i18n.translate('datenfile.info.write')}</p>
      `
    }
    return html`
      <isceco-title id="page-title"
                    text="${this.i18n.translate(title)}"
      ></isceco-title>

      ${description}

      ${this.renderForm()}
    `
  }

  renderForm = () => {
    const content = [
      new DossierRender(this).render(),
      new CompanyRender(this).render(),
      new EmployeeRender(this).render(),
      new PBKRender(this).render()
    ]
    if (this.read) {
      return html`
        <isceco-button id="datenfile-edit"
                       variant="primary"
                       text="${this.i18n.translate('datenfile.bearbeiten')}"
                       @click="${this.edit}"
        ></isceco-button>

        <isceco-form id="datenfile-form"
                     include-hidden include-disabled
                     .value="${this.metadaten}">
          <div slot="form-elements" class="read">
            ${content}
          </div>
        </isceco-form>
      `
    } else {
      return html`
        <isceco-form id="datenfile-form"
                     include-hidden include-disabled
                     .value="${this.metadaten}"
        >
          <div slot="form-elements" class="write">
            ${content}
          </div>
          <div slot="buttons">
            <isceco-button text="${this.i18n.translate('datenfile.speichern')}"
                           variant="primary"
                           @click="${this.submitFormTmp}"
            ></isceco-button>
          </div>
        </isceco-form>

        <isceco-dialog id="submit-dialog"
                       hidden
                       confirm-button="${this.i18n.translate('dialog.submit.save')}"
                       cancel-button="${this.i18n.translate('dialog.submit.cancel')}"
                       @submit="${this.submitDialog}">
          <isceco-text-input id="filename"
                             inline required
                             maxlength="255"
                             label="${this.i18n.translate('dialog.submit.name')}"
          ></isceco-text-input>
        </isceco-dialog>
      `
    }
  }

  edit = () => {
    const params = getNavigationParams()
    const newParams = {edit: true}
    if (params.has(DatenfileDetail.PARAM.ID)) {
      newParams.id = params.get(DatenfileDetail.PARAM.ID)
    } else if (params.has(DatenfileDetail.PARAM.DOSSIER)) {
      newParams.dossier = params.get(DatenfileDetail.PARAM.DOSSIER)
    }
    navigate({params: newParams})
  }

  submitFormTmp = () => {
    const form = this.querySelector('#datenfile-form')
    if (form.validate()) {
      this.submitForm(form.getValues())
    } else {
      const errors = form.querySelectorAll('.error')
      errors.forEach(this.openAllParents)
      errors[0].scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }

  openAllParents = element => {
    if (element.classList.contains('group')) {
      return
    }
    this.openAllParents(element.parentNode)

    if (element.classList.contains('section')) {
      element.classList.remove('priority')
      element.classList.add('all')
    } else if (element.tagName.toLowerCase() === 'details') {
      element.open = true
    }
  }

  submitForm = payload => {
    payload.Metadaten = this.metadaten.Metadaten
    const organisationService = new OrganisationService()
    organisationService.read(window.keycloak.tokenParsed.unitExtId)
      .then(json => {
        payload.LastSender = json.name
      })
      .catch(_ => {
        payload.LastSender = window.keycloak.tokenParsed.unitExtId
      })
      .finally(_ => {
        this.data = MetadatenDto.json(payload)
        const metadatenService = new MetadatenService()
        metadatenService.create(this.data, '/validate')
          .then(json => {
            const dialog = document.querySelector('#submit-dialog')
            dialog.description = this.i18n.translate(`valid.${json.valid}`)
            const filename = dialog.querySelector('#filename')
            filename.value = payload.Metadaten.name
            dialog.hidden = false
          })
      })
  }

  submitDialog = event => {
    if (event.detail.confirmed) {
      const filename = event.target.querySelector('#filename')
      if (filename.validate()) {
        this.data.name = filename.getValue()
        this.sendMetadaten()
        event.target.hidden = true
      }
    } else {
      event.target.hidden = true
    }
  }

  sendMetadaten = () => {
    const metadatenService = new MetadatenService()
    const promise = this.data.id ? metadatenService.update(this.data) : metadatenService.create(this.data)
    promise.then(json => {
      showAlert(this.i18n.translate('alert.success.title'), this.i18n.translate('alert.success.message', {name: json.name}), 'success')
      navigate({to: 'Datenfile'})
    })
  }
}

customElements.define('flam-datenfile-detail', DatenfileDetail)
