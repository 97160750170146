import Dossierstatus from './Dossierstatus.js'

export default class ActiveRow {
  get(row) {
    if (row.status === Dossierstatus.STATUS_KEYS.HOCHGELADEN ||
      row.status === Dossierstatus.STATUS_KEYS.NICHT_ABGEHOLT) {
      return 'summary'
    } else {
      return ''
    }
  }
}
