export default {
  de: {
    PO: 'Positiver Entscheid ohne Druck',
    PD: 'Positiver Entscheid mit Druck',
    NO: 'Negativer Entscheid ohne Druck',
    ND: 'Negativer Entscheid mit Druck',
    AO: 'Annullierte Meldung ohne Druck',
    AD: 'Annullierte Meldung mit Druck'
  },
  fr: {
    PO: 'positif non imprimé',
    PD: 'positif',
    NO: 'negatif non imprimé',
    ND: 'negatif',
    AO: 'annulé non imprimé',
    AD: 'annulé'
  },
  it: {
    PO: 'decisione positiva non stampata',
    PD: 'decisione positiva stampata',
    NO: 'decisione negativa non stampata',
    ND: 'decisione negativa stampata',
    AO: 'notifica annullata non stampata',
    AD: 'notifica annullata stampata'
  }
}
