export default {
  de: {
    10: 'Bauhauptgewerbe',
    20: 'Baunebengewerbe Elektro-, Gas-, Wasser-, Sanitär-, Heizungs-, Lüftungsinstallation, Spenglerei, Gipserei, Malerei, Fussbodenlegerei, Dämmung, Bauschlosserei',
    21: 'Baunebengewerbe, Montage, Reperatur, Service',
    30: 'Gastgewerbe',
    40: 'Reinigungsgewerbe (Gebäude, Wohnungen, Inventar und Vekehrsmittel)',
    50: 'Überwachungs- und Sicherungsgewerbe',
    110: 'Land- und Forstwirtschaft',
    120: 'Bergbau',
    210: 'Industrie/Herstellung von Waren',
    220: 'Verarbeitendes Gewerbe ohne Baunebengewerbe',
    221: 'Verarbeitendes Gewerbe (Montage, Reperatur, Service)',
    310: 'Handel',
    320: 'Banken / Versicherungen',
    410: 'Inmobilienwesen',
    420: 'Vermietung von Fahrzeugen, Maschinen, Geräten',
    510: 'Erbringung von Dienstleistungen Informatik',
    520: 'Erbringung von Dienstleistungen Forschung und Entwicklung',
    530: 'Erbringung von Rechts-, Steuer-, Unternehmensberatung, Buchführung',
    540: 'Personalverleih',
    610: 'Gesundheits- und Veterinärwesen, Heime, Kinderkrippen',
    620: 'Erbringung von Dienstleistungen Wäscherei, Coiffeur, Kosmetik, Fitnesszentren',
    630: 'Erbringung von Dienstleistungen private Haushalte',
    710: 'Öffentliche Verwaltung',
    720: 'Unterricht',
    730: 'Kirche, Kultur, Sport',
    740: 'Energie- und Wasserversorgung',
    750: 'Verkehr',
    760: 'Post-, Kurierdienst',
    810: 'Internationale Organisationen',
    820: 'NGO'
  },
  fr: {
    10: 'Gros œuvre',
    20: 'Second oeuvre électricité, gaz, eau, sanitaire,chauffage, aération, ferblanterie, plâtrerie,peinture, pose de sols, isolation, serrurerie)',
    21: 'Second oeuvre, montage, réparation, service',
    30: 'Hôtellerie et restauration',
    40: 'Nettoyage (bâtiments, appartements, inventaires et moyens de transports)',
    50: 'Surveillance et sécurité',
    110: 'Agriculture, sylviculture',
    120: 'Industries extractives',
    210: 'Industrie/production',
    220: 'Industries manufacturières, hormis le second oeuvre',
    221: 'Industries manufacturières montage, réparation, service',
    310: 'Commerce',
    320: 'Banques / Assurances',
    410: 'Inmobilier',
    420: 'Location de véhicules, machines, appareils',
    510: 'Prestations de services dans l\'informatique',
    520: 'Prestations de services dans la recherche et le développement',
    530: 'Prestations de conseil juridique, fiscal, en gestion, comptabilité',
    540: 'Location de services',
    610: 'Santé et affaires vétérinaires, homes, crèches',
    620: 'Prestations de services blanchisserie, coiffeur, cosmétique, centres de fitness',
    630: 'Prestations de services dans les ménages',
    710: 'Administration publique',
    720: 'Enseignement',
    730: 'Eglise, culture, sport',
    740: 'Approvisionnement en énergie et en eau',
    750: 'Transports',
    760: 'Activités de poste et de courrier',
    810: 'Organisations internationales',
    820: 'ONG'
  },
  it: {
    10: 'Edilizia e genio civile',
    20: 'Rami accessori dell\'edilizia, installazioni di impianti elettrici, idraulici, sanitari, di riscaldamento e d\'areazione, lattoneria, intonacatura, tinteggiatura, posa di pavimenti, lavori d\'isolamento, posa di infissi',
    21: 'Rami accessori dell\'edilizia, montaggio, riparazioni, servizi',
    30: 'Industria alberghiera e ristorazione',
    40: 'Lavori di pulizia (edifici, appartamenti, inventari e mezzi di trasporto)',
    50: 'Servizi di sorveglianza e di sicurezza',
    110: 'Agricoltura e silvicoltura',
    120: 'Attività estrattive',
    210: 'Industria/produzione di merci',
    220: 'Attività manifatturiere senza rami accessori dell\'edilizia',
    221: 'Attività manifatturiere (montaggio, riparazioni, servizi)',
    310: 'Commercio',
    320: 'Banche / assicurazioni',
    410: 'Settore immobiliare',
    420: 'Noleggio di veicoli, macchine e apparecchi',
    510: 'Servizi nell\'ambito dell\'informatica',
    520: 'Servizi nell\'ambito ricerca e sviluppo',
    530: 'Servizi nell\'ambito della consulenza legale, fiscale e gestionale; contabilità',
    540: 'Fornitura di personale a prestito',
    610: 'Settore sanitario e veterinario, case di cura, asili nido',
    620: 'Servizi nell\'ambito della  lavanderia, parrucchieri, cosmesi, palestre',
    630: 'Servizi per le economie domestiche private',
    710: 'Amministrazione pubblica',
    720: 'Insegnamento',
    730: 'Chiese, cultura, sport',
    740: 'Approvvigionamento energetico e idrico',
    750: 'Trasporti',
    760: 'Servizi postali e di corriere',
    810: 'Organizzazioni internazionali',
    820: 'ONG'
  }
}
