export default {
  de: {
    1: 'nein',
    2: 'Gratifikation',
    3: '13. Monatslohn',
    4: '13./14. Monatslohn'
  },
  fr: {
    1: 'non',
    2: 'gratification',
    3: '13e mois de salaire',
    4: '13e/14e mois de salaire'
  },
  it: {
    1: 'no',
    2: 'gratifica',
    3: '13° mensilità',
    4: '13°14° mensilità'
  }
}
