import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Footer/Footer.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WebComponent from '../../WebComponent.js'
import FooterService from './FooterService.js'

export default class Footer extends WebComponent {
  connectedCallback() {
    super.connectedCallback()
    this.version = ''
    this.reload()
  }

  get translationFile() {
    return './views/Footer/i18n.json'
  }

  reload() {
    const service = new FooterService()
    Promise.all([
      service.list(), fetch('./version.json', {cache: 'reload'}).then(r => r.json())
    ])
      .then(([backend, frontend]) => {
        this.version = `- (v${frontend.version}/${backend.version})`
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-footer text="${this.i18n.translate('footer.seco')} ${this.version}">
        <div>
          <isceco-link url="${this.i18n.translate('footer.bundesgesetz.link')}"
                       target="_blank"
                       text="${this.i18n.translate('footer.bundesgesetz')}"
          ></isceco-link>
          -
          <isceco-link url="${this.i18n.translate('footer.verordnung.link')}"
                       target="_blank"
                       text="${this.i18n.translate('footer.verordnung')}"
          ></isceco-link>
        </div>
      </isceco-footer>
    `
  }
}
customElements.define('flam-footer', Footer)
