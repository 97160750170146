export default {
  de: {
    AN: 'Arbeitnehmer',
    AG: 'Arbeitgeber',
    NK: 'nicht kontrolliert'
  },
  fr: {
    AN: 'travailleur',
    AG: 'employeur',
    NK: 'non contrôlé'
  },
  it: {
    AN: 'lavoratore',
    AG: 'datore di lavoro',
    NK: 'non controllato'
  }
}
