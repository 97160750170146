import {html, css} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Header/Header.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import '@isceco/widget-library2/basic-elements/LanguageSwitcher/LanguageSwitcher.js'
import OrganisationService from '../Organisation/OrganisationService.js'
import WebComponent from '../../WebComponent.js'

export default class Header extends WebComponent {

  get translationFile() {
    return './views/Header/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    const organisationService = new OrganisationService()
    organisationService.read(window.keycloak.tokenParsed.unitExtId)
      .then(json => {
        this.organisationName = json.name
        this.organisationAktiv = json.aktiv
        this.render()
      })
      .catch(_ => {
        this.organisationName = ''
        this.organisationAktiv = false
        this.render()
      })
  }

  get css() {
    return css`
      .user-language {
        display: flex;
        align-items: end;
        flex-direction: column;
      }
    `
  }


  getTemplate() {
    return html`
      <isceco-header id="header" text="${this.i18n.translate('header.seco')}">
        <div class="user-language">
          <isceco-language variant="horizontal"></isceco-language>
          <div>
            ${window.keycloak.tokenParsed.name} (${this.organisationName})
            <b>${this.organisationAktiv ? '' : this.i18n.translate('header.inaktiv')}</b>
          </div>
          <div>
            <isceco-link text="${window.keycloak.tokenParsed.email}"
                         url="Profil"
                         icon="user"
                         target="_self"
            ></isceco-link>
            (
            <isceco-link text="Logout"
                         @click="${_ => window.keycloak.logout()}"
                         icon="sign out"
            ></isceco-link>
            )
          </div>
        </div>
      </isceco-header>
    `
  }
}

customElements.define('flam-header', Header)
