export default {
  de: {
    3: 'Meldeverstoss',
    4: 'Verstoss gegen die Auskunftspflicht',
    5: 'Kontrollbericht',
    6: 'Meldung GAV-Verstoss',
    7: 'Rückmeldung an KV',
    8: 'Verstoss gegen die Dokumentationspflicht',
    9: 'Rückmeldung an PK'
  },
  fr: {
    3: 'Infraction à l\'obligation d\'annonce',
    4: 'Infraction à l\'obligation de renseigner ',
    5: 'Rapport de contrôle',
    6: 'Annonce d’infraction CCT',
    7: 'Retour d’information à l’ association de contrôle',
    8: 'Infraction à l\'obligation de  fournir la documentation',
    9: 'Retour d\'information à la commission paritaire'
  },
  it: {
    3: 'Violazione dell\'obbligo di notifica',
    4: 'Violazione dell’obbligodi dare informazioni',
    5: 'Rapporto di controllo',
    6: 'Segnalare una violazione del CCL',
    7: 'Feedback all\' associazione di controllo',
    8: 'Violazione dell’obbligo di presentare i documenti',
    9: 'Feedback alla commissione paritetica'
  }
}
