import {css} from '@isceco/widget-library2/external/lit'

export default css`

  /* GENERAL STYLING */

  #datenfile-form {
    margin-top: var(--isceco-space-300);
  }

  /* FILE CONTENT */

  .row {
    display: flex;
    align-items: center;
    background-color: white;
    border-top: 1px solid var(--isceco-color-grey-300);
    padding-left: var(--isceco-space-200);
    min-height: var(--isceco-space-400);
  }
  
  .row.header, .row.subheader, .row.collapsible {
    font-weight: bold;
    border-top: 1px solid var(--isceco-color-grey-500);
    padding-right: var(--isceco-space-200);
  }

  .row.header {
    background-color: var(--isceco-color-grey-500);
  }

  .row.subheader {
    background-color: var(--isceco-color-grey-300);
  }

  .row.collapsible {
    background-color: var(--isceco-color-grey-100);
  }

  .row.value {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .row.value > label {
    width: 50%;
  }

  .row.value > .right {
    flex: 1;
  }

  .row.value > isceco-popup.right > * {
    width: 100%;
  }

  *:after {
    float: right;
    margin-left: auto;
  }

  .inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: var(--isceco-space-200);
  }

  .inline > * {
    flex: 1;
  }

  .section .row.value .right > isceco-checkbox {
    margin-left: var(--isceco-space-200);
  }
  
  .row.header:has(.info) {
    display: block;
    padding: var(--isceco-space-200);
  }

  .info {
    font-weight: normal;
    margin-top: var(--isceco-space-200);
    display: block;
  }

  /* COLLAPSE */

  .row.collapsible {
    cursor: pointer;
  }

  .section .row.value {
    display: none;
  }

  .section.all .row.value,
  .section.priority .row.value.priority {
    display: flex;
  }

  .section .collapsible:after {
    content: '++';
    float: right;
    font-weight: bold;
  }

  .section.priority .collapsible:after {
    content: '+';
  }

  .section.all .collapsible:after {
    content: '-';
  }

  /* READ VIEW */

  .read .edit {
    display: none;
  }

  /* WRITE VIEW */

  .write .row.required {
    --isceco-color-white-500: var(--isceco-color-blue-100);
    background-color: var(--isceco-color-blue-100);
  }

  [icon=x] {
    --isceco-color-blue-500: var(--isceco-color-red-500);
  }

  .collapsible > .inline [icon=x],
  summary > .inline [icon=x] {
    margin: -10px 0;
    background-color: green;
  }

  /* details / summary */

  details > summary {
    list-style: none;
    cursor: pointer;
  }

  details[open] > summary:after {
    content: '-';
  }

  details > summary::marker,
  details > summary::-webkit-details-marker {
    display: none;
  }
`
