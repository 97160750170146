import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'

export default class NoContent extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <img src="https://media.giphy.com/media/Ss5nyLJYk0hY5HgnH9/giphy.gif" alt="not found"/>
      </div>
    `
  }

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }
}

customElements.define('no-content', NoContent)
