import '@isceco/widget-library2/basic-elements/Card/Card.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import ViewFilter from '../Navigation/ViewFilter.js'

export default class Landing extends WebComponent {

  get translationFile() {
    return './views/Landing/i18n.json'
  }

  get css() {
    return css`
      .landing-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .landing-item {
        flex: 1;
        cursor: pointer;
        min-width: 40%;
        margin: 1rem;
        max-width: 50%;
      }

      .landing-item > isceco-card {
        height: 7rem;
      }

      .landing-item > isceco-card:hover {
        background-color: var(--isceco-color-grey-100)
      }

      .landing-item > isceco-card.organisation {
        color: #68738d;
      }

      .landing-item > isceco-card.senden {
        color: #8d6885;
      }

      .landing-item > isceco-card.gesendete {
        color: #8d8268;
      }

      .landing-item > isceco-card.empfangen {
        color: #688d6f;
      }

      .landing-item > isceco-card.dossier {
        color: #7c8c68;
      }

      .landing-item > isceco-card.datenfile {
        color: #53706f;
      }

      @media (max-width: 991.98px) {
        .landing-item {
          min-width: 80%;
        }
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('landing.titel')}"></isceco-title>

      <div class="landing-wrapper">
        ${this.getCardsTemplate()}
      </div>
    `
  }

  getCardsTemplate() {
    const allItems = {
      Organisation: {
        class: 'organisation',
        title: 'landing.organisation',
        text: 'landing.organisation.description',
        icon: 'users',
        url: 'Organisation'
      },
      Senden: {
        class: 'senden',
        title: 'landing.senden',
        text: 'landing.senden.description',
        icon: 'paper plane',
        url: 'Senden'
      },
      Gesendet: {
        class: 'gesendete',
        title: 'landing.gesendete',
        text: 'landing.gesendete.description',
        icon: 'envelope',
        url: 'Gesendet'
      },
      Empfangen: {
        class: 'empfangen',
        title: 'landing.empfangen',
        text: 'landing.empfangen.description',
        icon: 'inbox',
        url: 'Empfangen'
      },
      Dossier: {
        class: 'dossier',
        title: 'landing.dossier',
        text: 'landing.dossier.description',
        icon: 'list alternate',
        url: 'Dossier'
      },
      Datenfile: {
        class: 'datenfile',
        title: 'landing.datenfile',
        text: 'landing.datenfile.description',
        icon: 'pen',
        url: 'Datenfile'
      }
    }
    const items = new ViewFilter().viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    return Object.values(items).map(item =>
      html`
        <isceco-link class="landing-item" url="${item.url}">
          <isceco-card class="${item.class}" text="${this.i18n.translate(item.title)}">
            <i class="${item.icon} icon"></i>
            ${this.i18n.translate(item.text)}
          </isceco-card>
        </isceco-link>
      `
    )
  }
}

customElements.define('flam-landing', Landing)
