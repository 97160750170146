export default {
  de: {
    1390: 'Stellenantritt CH',
    1391: 'Selbstständig',
    1392: 'Entsendebetrieb'
  },
  fr: {
    1390: 'prise d\'emploi CH',
    1391: 'indépendant',
    1392: 'entreprise détachant des travailleurs'
  },
  it: {
    1390: 'assunzione d\'impiego CH',
    1391: 'indipendente',
    1392: 'impresa che distacca lavoratori'
  }
}
