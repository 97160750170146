export default {
  de: {
    1: 'Identitätskarte/Personalausweis',
    2: 'Pass',
    3: 'Ausländerausweis',
    4: 'Führerausweis',
    5: 'Andere',
    6: 'kein Ausweis'
  },
  fr: {
    1: 'carte d\'identité',
    2: 'passeport',
    3: 'permis de séjour',
    4: 'permis de conduire',
    5: 'autres',
    6: 'pas de document d\'identité'
  },
  it: {
    1: 'carta d\'identità',
    2: 'passaporto',
    3: 'carta di soggiorno',
    4: 'licenza di condurre',
    5: 'altri',
    6: 'nessun documento di identità'
  }
}
