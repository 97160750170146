export default {
  de: {
    AG: 'Aargau',
    AI: 'Appenzell Innerrhoden',
    AR: 'Appenzell Ausserrhoden',
    BE: 'Bern',
    BL: 'Baselland',
    BS: 'Basel-Stadt',
    FR: 'Freiburg',
    GE: 'Genf',
    GL: 'Glarus',
    GR: 'Graubünden',
    JU: 'Jura',
    LU: 'Luzern',
    NE: 'Neuenburg',
    NW: 'Niedwalden',
    OW: 'Obwalden',
    SG: 'St. Gallen',
    SH: 'Schaffhausen',
    SO: 'Solothurn',
    SZ: 'Schwyz',
    TG: 'Thurgau',
    TI: 'Tessin',
    UR: 'Uri',
    VD: 'Vaadt',
    VS: 'Wallis',
    ZG: 'Zug',
    ZH: 'Zürich'
  },
  fr: {
    AG: 'Argovie',
    AI: 'Appenzell Rhodes-Intérieures',
    AR: 'Appenzell Rhodes-Extérieures',
    BE: 'Berne',
    BL: 'Bâle-Campagne',
    BS: 'Bâle-Ville',
    FR: 'Fribourg',
    GE: 'Genève',
    GL: 'Glaris',
    GR: 'Grisons',
    JU: 'Jura',
    LU: 'Lucerne',
    NE: 'Neuchâtel',
    NW: 'Nidwald',
    OW: 'Obwald',
    SG: 'Saint-Gall',
    SH: 'Schaffhouse',
    SO: 'Soleure',
    SZ: 'Schwyz',
    TG: 'Thurgovie',
    TI: 'Tessin',
    UR: 'Uri',
    VD: 'Vaud',
    VS: 'Valais',
    ZG: 'Zoug',
    ZH: 'Zurich'
  },
  it: {
    AG: 'Argovia',
    AI: 'Appenzello Interno',
    AR: 'Appenzello Esterno',
    BE: 'Berna',
    BL: 'Basilea Campagna',
    BS: 'Basilea Città',
    FR: 'Friburgo',
    GE: 'Ginevra',
    GL: 'Glarona',
    GR: 'Grigioni',
    JU: 'Giura',
    LU: 'Lucerna',
    NE: 'Neuchâtel',
    NW: 'Nidvaldo',
    OW: 'Obvaldo',
    SG: 'San Gallo',
    SH: 'Sciaffusa',
    SO: 'Soletta',
    SZ: 'Svitto',
    TG: 'Turgovia',
    TI: 'Ticino',
    UR: 'Uri',
    VD: 'Vaud',
    VS: 'Vallese',
    ZG: 'Zugo',
    ZH: 'Zurigo'
  }
}
