export default {
  de: {
    1: 'nicht bezahlt',
    2: 'kompensiert 1:1',
    3: 'kompensiert mit Zuschlag',
    4: 'ausbezahlt',
    5: 'ausbezahlt mit Zuschlag'
  },
  fr: {
    1: 'non payées',
    2: 'compensées 1:1',
    3: 'compensées avec supplément',
    4: 'payées',
    5: 'payées avec supplément'
  },
  it: {
    1: 'non pagate',
    2: 'compensate 1:1',
    3: 'compensate con supplemento',
    4: 'pagate',
    5: 'pagate con supplemento'
  }
}
