export default class Dossierstatus {
  // @alican Object freeze und als const
  static get STATUS_KEYS() {
    return {
      HOCHGELADEN: 0,
      HERUNTERGELADEN: 1,
      VERARBEITET: 2,
      NICHT_ABGEHOLT: 3,
      ZURUECKGEWIESEN: 4,
      ARCHIVIERT: 5
    }
  }

  static get OPTIONS() {
    return Object.values(Dossierstatus.STATUS_KEYS).map(id => ({
      value: id,
      name: `status.${id}`
    }))
  }
}
