export default {
  de: {
    100: 'Schweiz',
    201: 'Albanien',
    202: 'Andorra',
    204: 'Belgien',
    205: 'Bulgarien',
    206: 'Dänemark',
    207: 'Deutschland',
    211: 'Finnland',
    212: 'Frankreich',
    214: 'Griechenland',
    215: 'Vereinigtes Königreich',
    216: 'Irland',
    217: 'Island',
    218: 'Italien',
    222: 'Liechtenstein',
    223: 'Luxemburg',
    224: 'Malta',
    226: 'Monaco',
    227: 'Niederlande',
    228: 'Norwegen',
    229: 'Oesterreich',
    230: 'Polen',
    231: 'Portugal',
    232: 'Rumänien',
    233: 'San Marino',
    234: 'Schweden',
    236: 'Spanien',
    239: 'Türkei',
    240: 'Ungarn',
    241: 'Vatikanstadt',
    242: 'Zypern',
    243: 'Slowakei',
    244: 'Tschechische Republik',
    248: 'Serbien',
    250: 'Kroatien',
    251: 'Slowenien',
    252: 'Bosnien und Herzegowina',
    254: 'Montenegro',
    255: 'Mazedonien',
    256: 'Kosovo',
    260: 'Estland',
    261: 'Lettland',
    262: 'Litauen',
    263: 'Moldova',
    264: 'Russland',
    265: 'Ukraine',
    266: 'Belarus',
    301: 'Aequatorialguinea',
    302: 'Aethiopien',
    303: 'Dschibuti',
    304: 'Algerien',
    305: 'Angola',
    307: 'Botsuana',
    308: 'Burundi',
    309: 'Benin',
    310: 'Côte d’Ivoire',
    311: 'Gabun',
    312: 'Gambia',
    313: 'Ghana',
    314: 'Guinea-Bissau',
    315: 'Guinea',
    317: 'Kamerun',
    319: 'Kap Verde',
    320: 'Kenia',
    321: 'Komoren',
    322: 'Kongo (Brazzaville)',
    323: 'Kongo (Kinshasa)',
    324: 'Lesotho',
    325: 'Liberia',
    326: 'Libyen',
    327: 'Madagaskar',
    329: 'Malawi',
    330: 'Mali',
    331: 'Marokko',
    332: 'Mauretanien',
    333: 'Mauritius',
    334: 'Mosambik',
    335: 'Niger',
    336: 'Nigeria',
    337: 'Burkina Faso',
    340: 'Simbabwe',
    341: 'Ruanda',
    343: 'Sambia',
    344: 'Sao Tomé und Principe',
    345: 'Senegal',
    346: 'Seychellen',
    347: 'Sierra Leone',
    348: 'Somalia',
    349: 'Südafrika',
    350: 'Sudan',
    351: 'Namibia',
    352: 'Swasiland',
    353: 'Tansania',
    354: 'Togo',
    356: 'Tschad',
    357: 'Tunesien',
    358: 'Uganda',
    359: 'Aegypten',
    360: 'Zentralafrikanische Republik',
    362: 'Eritrea',
    372: 'Westsahara',
    401: 'Argentinien',
    402: 'Bahamas',
    403: 'Barbados',
    405: 'Bolivien',
    406: 'Brasilien',
    407: 'Chile',
    408: 'Costa Rica',
    409: 'Dominikanische Republik',
    410: 'Ecuador',
    411: 'El Salvador',
    415: 'Guatemala',
    417: 'Guyana',
    418: 'Haiti',
    419: 'Belize',
    420: 'Honduras',
    421: 'Jamaika',
    423: 'Kanada',
    424: 'Kolumbien',
    425: 'Kuba',
    427: 'Mexiko',
    429: 'Nicaragua',
    430: 'Panama',
    431: 'Paraguay',
    432: 'Peru',
    435: 'Suriname',
    436: 'Trinidad und Tobago',
    437: 'Uruguay',
    438: 'Venezuela',
    439: 'Vereinigte Staaten (USA)',
    440: 'Dominica',
    441: 'Grenada',
    442: 'Antigua und Barbuda',
    443: 'St. Lucia',
    444: 'St. Vincent und die Grenadinen',
    445: 'St. Kitts und Nevis',
    501: 'Afghanistan',
    502: 'Bahrain',
    503: 'Bhutan',
    504: 'Brunei Darussalam',
    505: 'Myanmar',
    506: 'Sri Lanka',
    507: 'China (Taiwan)',
    508: 'China',
    510: 'Indien',
    511: 'Indonesien',
    512: 'Irak',
    513: 'Iran',
    514: 'Israel',
    515: 'Japan',
    516: 'Jemen',
    517: 'Jordanien',
    518: 'Kambodscha',
    519: 'Katar',
    521: 'Kuwait',
    522: 'Laos',
    523: 'Libanon',
    525: 'Malaysia',
    526: 'Malediven',
    527: 'Oman',
    528: 'Mongolei',
    529: 'Nepal',
    530: 'Korea (Nord-)',
    532: 'Vereinigte arabische Emirate',
    533: 'Pakistan',
    534: 'Philippinen',
    535: 'Saudi-Arabien',
    537: 'Singapur',
    539: 'Korea (Süd-)',
    541: 'Syrien',
    542: 'Thailand',
    545: 'Vietnam',
    546: 'Bangladesch',
    547: 'Timor-Leste',
    550: 'Palästina',
    560: 'Armenien',
    561: 'Aserbaidschan',
    562: 'Georgien',
    563: 'Kasachstan',
    564: 'Kirgisistan',
    565: 'Tadschikistan',
    566: 'Turkmenistan',
    567: 'Usbekistan',
    601: 'Australien',
    602: 'Fidschi (Inseln)',
    604: 'Nauru',
    605: 'Vanuatu',
    607: 'Neuseeland',
    608: 'Papua-Neuguinea',
    610: 'Tonga',
    612: 'Samoa',
    614: 'Salomoninseln',
    615: 'Tuvalu',
    616: 'Kiribati',
    617: 'Marshallinseln',
    618: 'Mikronesien',
    619: 'Palau',
    997: 'Staat unbekannt gemäss Simic',
    998: 'Staatenlos',
    999: 'Staat unbekannt oder nicht angegeben'
  },
  fr: {
    100: 'Suisse',
    201: 'Albanie',
    202: 'Andorre',
    204: 'Belgique',
    205: 'Bulgarie',
    206: 'Danemark',
    207: 'Allemagne',
    211: 'Finlande',
    212: 'France',
    214: 'Grèce',
    215: 'Royaume-Uni',
    216: 'Irelande',
    217: 'Islande',
    218: 'Italie',
    222: 'Liechtenstein',
    223: 'Luxembourg',
    224: 'Malte',
    226: 'Monaco',
    227: 'Pays-Bas',
    228: 'Norvège',
    229: 'Autriche',
    230: 'Pologne',
    231: 'Portugal',
    232: 'Roumanie',
    233: 'Saint-Marin',
    234: 'Suède',
    236: 'Espagne',
    239: 'Turquie',
    240: 'Hongrie',
    241: 'Cité du Vatican',
    242: 'Chypre',
    243: 'Slovaquie',
    244: 'République Tchèque',
    248: 'Serbie',
    250: 'Croatie',
    251: 'Slovénie',
    252: 'Bosnie et Herzégovine',
    254: 'Monténégro',
    255: 'Macédoine',
    256: 'Kosovo',
    260: 'Estonie',
    261: 'Lettonie',
    262: 'Lituanie',
    263: 'Moldavie',
    264: 'Russie',
    265: 'Ukraine',
    266: 'Bélarus',
    301: 'Guinée équatoriale',
    302: 'Ethiopie',
    303: 'Djibouti',
    304: 'Algérie',
    305: 'Angola',
    307: 'Botswana',
    308: 'Burundi',
    309: 'Bénin',
    310: 'Côte d’Ivoire',
    311: 'Gabon',
    312: 'Gambie',
    313: 'Ghana',
    314: 'Guinée-Bissau',
    315: 'Guinée',
    317: 'Cameroun',
    319: 'Cap-Vert',
    320: 'Kenya',
    321: 'Comores',
    322: 'Congo (Brazzaville)',
    323: 'Congo (Kinshasa)',
    324: 'Lesotho',
    325: 'Libéria',
    326: 'Libye',
    327: 'Madagascar',
    329: 'Malawi',
    330: 'Mali',
    331: 'Maroc',
    332: 'Mauritanie',
    333: 'Maurice',
    334: 'Mosambique',
    335: 'Niger',
    336: 'Nigéria',
    337: 'Burkina Faso',
    340: 'Zimbabwe',
    341: 'Rwanda',
    343: 'Zambie',
    344: 'Sao Tomé-et-Principe',
    345: 'Sénégal',
    346: 'Seychelles',
    347: 'Sierra-Leone',
    348: 'Somalie',
    349: 'Afrique du Sud',
    350: 'Soudan',
    351: 'Namibie',
    352: 'Swaziland',
    353: 'Tanzanie',
    354: 'Togo',
    356: 'Tchad',
    357: 'Tunisie',
    358: 'Ouganda',
    359: 'Egypte',
    360: 'République centrafricaine',
    362: 'Erythrée',
    372: 'Sahara Occidental',
    401: 'Argentine',
    402: 'Bahamas',
    403: 'Barbade',
    405: 'Bolivie',
    406: 'Brésil',
    407: 'Chili',
    408: 'Costa Rica',
    409: 'République dominicaine',
    410: 'Equateur',
    411: 'El Salvador',
    415: 'Guatemala',
    417: 'Guyana',
    418: 'Haïti',
    419: 'Belize',
    420: 'Honduras',
    421: 'Jamaïque',
    423: 'Canada',
    424: 'Colombie',
    425: 'Cuba',
    427: 'Mexique',
    429: 'Nicaragua',
    430: 'Panama',
    431: 'Paraguay',
    432: 'Pérou',
    435: 'Suriname',
    436: 'Trinité-et-Tobago',
    437: 'Uruguay',
    438: 'Venezuela',
    439: 'Etats-Unis d\'Amérique(USA)',
    440: 'Dominique',
    441: 'Grenade',
    442: 'Antigua-et-Barbuda',
    443: 'Sainte-Lucie',
    444: 'Saint-Vincent-et-les-Grenadines',
    445: 'Saint-Kitts-et-Nevis',
    501: 'Afghanistan',
    502: 'Bahreïn',
    503: 'Bhoutan',
    504: 'Brunéi',
    505: 'Myanmar',
    506: 'Sri Lanka',
    507: 'Chine (Taïwan)',
    508: 'Chine',
    510: 'Inde',
    511: 'Indonésie',
    512: 'Iraq',
    513: 'Iran',
    514: 'Israël',
    515: 'Japon',
    516: 'Yémen',
    517: 'Jordani',
    518: 'Cambodge',
    519: 'Qatar',
    521: 'Koweït',
    522: 'Laos',
    523: 'Liban',
    525: 'Malaisie',
    526: 'Maldives',
    527: 'Oman',
    528: 'Mongolie',
    529: 'Népal',
    530: 'Corée (Nord)',
    532: 'Emirats arabes unis',
    533: 'Pakistan',
    534: 'Philippines',
    535: 'Arabie saoudite',
    537: 'Singapour',
    539: 'Corée (Sud)',
    541: 'Syrie',
    542: 'Thaïlande',
    545: 'Vietnam',
    546: 'Bangladesh',
    547: 'Timor-Leste',
    550: 'Palestine',
    560: 'Arménie',
    561: 'Azerbaïdjan',
    562: 'Géorgie',
    563: 'Kazakhstan',
    564: 'Kirghizistan',
    565: 'Tadjikistan',
    566: 'Turkménistan',
    567: 'Ouzbékistan',
    601: 'Australie',
    602: 'Fidji (Iles)',
    604: 'Nauru',
    605: 'Vanuatu',
    607: 'Nouvelle-Zélande',
    608: 'Papouasie-Nouvelle-Guinée',
    610: 'Tonga',
    612: 'Samoa',
    614: 'Iles Salomon',
    615: 'Tuvalu',
    616: 'Kiribati',
    617: 'Iles Marshall',
    618: 'Micronésie',
    619: 'Palau',
    997: 'Nationalité inconnue selon Symic',
    998: 'Apatrides',
    999: 'Nationalité inconnue ou non indiquée'
  },
  it: {
    100: 'Svizzera',
    201: 'Albania',
    202: 'Andorra',
    204: 'Belgio',
    205: 'Bulgaria',
    206: 'Danimarca',
    207: 'Germania',
    211: 'Finlandia',
    212: 'Francia',
    214: 'Grecia',
    215: 'Regno Unito',
    216: 'Irlanda',
    217: 'Islanda',
    218: 'Italia',
    222: 'Liechtenstein',
    223: 'Lussemburgo',
    224: 'Malta',
    226: 'Monaco',
    227: 'Paesi Bassi',
    228: 'Norvegia',
    229: 'Austria',
    230: 'Polonia',
    231: 'Portogallo',
    232: 'Romania',
    233: 'San Marino',
    234: 'Svezia',
    236: 'Spagna',
    239: 'Turchia',
    240: 'Ungheria',
    241: 'Città del Vaticano',
    242: 'Cipro',
    243: 'Slovacchia',
    244: 'Cechia',
    248: 'Serbia',
    250: 'Croazia',
    251: 'Slovenia',
    252: 'Bosnia e Erzegovina',
    254: 'Montenegro',
    255: 'Macedonia',
    256: 'Kosovo',
    260: 'Estonia',
    261: 'Lettonia',
    262: 'Lituania',
    263: 'Moldova',
    264: 'Russia',
    265: 'Ucraina',
    266: 'Bielorussia',
    301: 'Guinea equatoriale',
    302: 'Etiopia',
    303: 'Gibuti',
    304: 'Algeria',
    305: 'Angola',
    307: 'Botswana',
    308: 'Burundi',
    309: 'Benin',
    310: 'Costa d\'Avorio',
    311: 'Gabon',
    312: 'Gambia',
    313: 'Ghana',
    314: 'Guinea-Bissau',
    315: 'Guinea',
    317: 'Camerun',
    319: 'Cabo Verde',
    320: 'Kenia',
    321: 'Comore',
    322: 'Kongo (Brazzaville)',
    323: 'Kongo (Kinshasa)',
    324: 'Lesotho',
    325: 'Liberia',
    326: 'Libia',
    327: 'Madagascar',
    329: 'Malawi',
    330: 'Mali',
    331: 'Marocco',
    332: 'Mauritania',
    333: 'Maurizio',
    334: 'Mozambico',
    335: 'Niger',
    336: 'Nigeria',
    337: 'Burkina Faso',
    340: 'Zimbabwe',
    341: 'Ruanda',
    343: 'Zambia',
    344: 'São Tomé e Principe',
    345: 'Senegal',
    346: 'Seicelle',
    347: 'Sierra Leone',
    348: 'Somalia',
    349: 'Sudafrica',
    350: 'Sudan',
    351: 'Namibia',
    352: 'Eswatini',
    353: 'Tanzania',
    354: 'Togo',
    356: 'Ciad',
    357: 'Tunisia',
    358: 'Uganda',
    359: 'Egitto',
    360: 'Repubblica centrafricana',
    362: 'Eritrea',
    372: 'Sahara Occidentale',
    401: 'Argentina',
    402: 'Bahamas',
    403: 'Barbados',
    405: 'Bolivia',
    406: 'Brasile',
    407: 'Cile',
    408: 'Costa Rica',
    409: 'Repubblica dominicana',
    410: 'Ecuador',
    411: 'El Salvador',
    415: 'Guatemala',
    417: 'Guyana',
    418: 'Haiti',
    419: 'Belize',
    420: 'Honduras',
    421: 'Giamaica',
    423: 'Canada',
    424: 'Colombia',
    425: 'Cuba',
    427: 'Messico',
    429: 'Nicaragua',
    430: 'Panama',
    431: 'Paraguay',
    432: 'Perù',
    435: 'Suriname',
    436: 'Trinidad e Tobago',
    437: 'Uruguay',
    438: 'Venezuela',
    439: 'Stati Uniti (USA)',
    440: 'Dominica',
    441: 'Grenada',
    442: 'Antigua e Barbuda',
    443: 'Saint Lucia',
    444: 'Saint Vincent e Grenadine',
    445: 'Saint Kitts e Nevis',
    501: 'Afghanistan',
    502: 'Bahrein',
    503: 'Bhutan',
    504: 'Brunei Darussalam',
    505: 'Myanmar',
    506: 'Sri Lanka',
    507: 'Cina (Taiwan)',
    508: 'Cina',
    510: 'India',
    511: 'Indonesia',
    512: 'Iraq',
    513: 'Iran',
    514: 'Israele',
    515: 'Giappone',
    516: 'Yemen',
    517: 'Giordania',
    518: 'Cambogia',
    519: 'Qatar',
    521: 'Kuwait',
    522: 'Laos',
    523: 'Libanon',
    525: 'Malaysia',
    526: 'Maldive',
    527: 'Oman',
    528: 'Mongolia',
    529: 'Nepal',
    530: 'Corea (Nord)',
    532: 'Emirati arabi uniti',
    533: 'Pakistan',
    534: 'Filippine',
    535: 'Arabia Saudita',
    537: 'Singapore',
    539: 'Corea (Sud)',
    541: 'Siria',
    542: 'Tailandia',
    545: 'Vietnam',
    546: 'Bangladesh',
    547: 'Timor-Leste',
    550: 'Palestina',
    560: 'Armenia',
    561: 'Azerbaigian',
    562: 'Georgia',
    563: 'Kazakistan',
    564: 'Kirghizistan',
    565: 'Tagikistan',
    566: 'Turkmenistan',
    567: 'Uzbekistan',
    601: 'Australia',
    602: 'Figi (Isole)',
    604: 'Nauru',
    605: 'Vanuatu',
    607: 'Nuova Zelanda',
    608: 'Papua Nuova Guinea',
    610: 'Tonga',
    612: 'Samoa',
    614: 'Isole Salomone',
    615: 'Tuvalu',
    616: 'Kiribati',
    617: 'Isole Marshall',
    618: 'Micronesia',
    619: 'Palau',
    997: 'Nazionalità ignota secondo SIMIC',
    998: 'Apolide',
    999: 'Nazionalità ignota o non indicata'
  }
}
