import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import ButtonCell from '@isceco/widget-library2/basic-elements/List/cell/ButtonCell.js'
import LinkCell from '@isceco/widget-library2/basic-elements/List/cell/LinkCell.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import Meldeart from './Meldeart.js'
import Dossierstatus from './Dossierstatus.js'
import StatusCell from './StatusCell.js'

export default class DossierColumns {

  static VERARBEITET_COLUMN(configuration) {
    return new CheckboxColumn({
      key: 'verarbeitet',
      text: 'table.header.verarbeitet',
      callback: configuration.callback,
      disabled: configuration.disabled
    })
  }

  static ZURUECKWEISEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'zurueckweisen',
      text: 'table.header.zurueckweisen',
      cell: new ButtonCell({
        callback: configuration.callback,
        disabled: configuration.disabled,
        text: 'table.zurueckweisen'
      })
    })
  }

  static CODE_COLUMN() {
    return new StringColumn({
      key: 'code',
      text: 'table.header.dossiernummer',
      sortable: true
    })
  }

  static EMPFAENGER_COLUMN(options) {
    return new DropdownMultiselectColumn({
      key: 'empfaenger',
      text: 'table.header.empfaenger',
      items: options.map(opt => Object.assign({}, opt)),
      sortable: true
    })
  }

  static SENDER_COLUMN(options) {
    return new DropdownMultiselectColumn({
      key: 'sender',
      text: 'table.header.sender',
      items: options.map(opt => Object.assign({}, opt)),
      sortable: true
    })
  }

  static MELDEART_COLUMN() {
    return new DropdownMultiselectColumn({
      key: 'art',
      text: 'table.header.meldeart',
      items: Meldeart.OPTIONS,
      sortable: true
    })
  }

  static METADATEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'metadatenShort',
      text: 'table.header.datenfile',
      cell: new LinkCell(configuration),
      sortable: true
    })
  }

  static NUTZDATEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'nutzdatenShort',
      text: 'table.header.pdffile',
      cell: new LinkCell(configuration),
      sortable: true
    })
  }

  static METADATEN_COLUMN_READONLY() {
    return new StringColumn({
      key: 'metadatenShort',
      text: 'table.header.datenfile',
      sortable: true
    })
  }

  static NUTZDATEN_COLUMN_READONLY() {
    return new StringColumn({
      key: 'nutzdatenShort',
      text: 'table.header.pdffile',
      sortable: true
    })
  }

  static SENDEDATUM_COLUMN() {
    return new DateColumn({
      key: 'created',
      text: 'table.header.sendedatum',
      sortable: true
    })
  }

  static BEARBEITETDATUM_COLUMN() {
    return new DateColumn({
      key: 'changed',
      text: 'table.header.bearbeitetdatum',
      sortable: true
    })
  }

  static STATUS_COLUMN(archivedText) {
    return new GenericColumn({
      key: 'status',
      text: 'table.header.status',
      cell: new StatusCell({items: Dossierstatus.OPTIONS, archivedText: archivedText}),
      sortable: true
    })
  }
}
