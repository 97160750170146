import {html} from '@isceco/widget-library2/external/lit'

export default class Render {

  constructor(detail) {
    this.detail = detail
  }

  get metadaten() {
    return this.detail.metadaten
  }

  get read() {
    return this.detail.read
  }

  get i18n() {
    return this.detail.i18n
  }

  text = ({key, exclude, maxLength, priority = false, required = false, info = false}) =>
    this.entry({
      content: html`
        <isceco-text-input inline
                           ?readonly="${this.read}"
                           maxlength="${maxLength}"
                           name="${key}"
        ></isceco-text-input>
      `, key: key, exclude: exclude, priority: priority, required: required, info: info
    })

  select = ({key, exclude, items, priority = false, required = false, info = false}) =>
    this.entry({
      content: html`
        <isceco-dropdown inline
                         ?readonly="${this.read}"
                         .items="${items}"
                         name="${key}"
        ></isceco-dropdown>
      `, key: key, exclude: exclude, priority: priority, required: required, info: info
    })

  date = ({key, exclude, priority = false, required = false, info = false}) =>
    this.entry({
      content: html`
        <isceco-date-input inline
                           ?readonly="${this.read}"
                           name="${key}"
        ></isceco-date-input>
      `, key: key, exclude: exclude, priority: priority, required: required, info: info
    })

  number = ({key, exclude, min = 0, max, digits = 0, priority = false, required = false, info = false}) =>
    this.entry({
      content: html`
        <isceco-number-input inline
                             ?readonly="${this.read}"
                             min="${min}"
                             max="${max}"
                             maximum-fraction-digits="${digits}"
                             name="${key}"
        ></isceco-number-input>
      `, key: key, exclude: exclude, priority: priority, required: required, info: info
    })

  checkbox = ({key, exclude, priority = false, required = false, info = false}) =>
    this.entry({
      content: html`
        <isceco-checkbox ?disabled="${this.read}"
                         name="${key}"
                         label=" "
        ></isceco-checkbox>
      `, key: key, exclude: exclude, priority: priority, required: required, info: info
    })

  entry = ({content, key, exclude, priority = false, required = false, info = false}) => {
    let right = ''
    if (info) {
      let infoText = this.translateInfo(key, exclude)
      if (infoText.includes(' | ')) {
        const [text, url] = infoText.split(' | ')
        infoText = html`
          <a href="${url}" target="_blank"
             style="color: white;">
            ${text}
          </a>
        `
      }
      right = html`
        <isceco-popup class="asdf" style="float: right;">
          <i slot="wrapper" class="icon info circle"></i>
          <div slot="content">
            ${infoText}
          </div>
        </isceco-popup>
      `
    }
    return html`
      <div class="value row ${this.priority(priority)} ${this.required(required)}">
        <label>
          ${this.translate(key, exclude)}
          ${right}
        </label>
        <div class="right">
          ${content}
        </div>
      </div>
    `
  }

  translateInfo = (key, exclude) => this.translate(`${key}.info`, exclude)

  translate = (key, exclude) => {
    if (exclude) {
      if (exclude instanceof Array) {
        for (const e of exclude) {
          key = key.replace(e, '')
        }
      } else {
        key = key.replace(exclude, '')
      }
    }
    return this.i18n.translate(`label.${key.toLowerCase()}`)
  }

  priority = bool => bool ? 'priority' : ''
  required = bool => bool ? 'required' : ''

  rotatePriority = event => {
    const priorityClass = this.priority(true)
    const allClass = 'all'
    const section = event.target.parentElement
    if (section.classList.contains(priorityClass)) {
      section.classList.remove(priorityClass)
      section.classList.add(allClass)
    } else if (section.classList.contains(allClass)) {
      section.classList.remove(allClass)
    } else {
      section.classList.add(priorityClass)
    }
  }

  redraw = () => this.detail.render()

  remove = id => {
    const item = this.detail.querySelector(id)
    if (item) {
      item.remove()
    }
  }
}
