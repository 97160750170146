export default class Flam {
  // @alican Object freeze und als const
  static ROLES() {
    return {
      admin: 'SECO-flam.Admin',
      superuser: 'SECO-flam.Superuser',
      user: 'SECO-flam.Sachbearbeiter'
    }
  }
}
