import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import ButtonCell from '@isceco/widget-library2/basic-elements/List/cell/ButtonCell.js'
import LinkCell from '@isceco/widget-library2/basic-elements/List/cell/LinkCell.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn'

export default class DatenfileColumns {

  static NAME_COLUMN(configuration) {
    return new GenericColumn({
      key: 'name',
      text: 'table.header.name',
      cell: new LinkCell(configuration),
      sortable: true,
      weight: 4
    })
  }

  static CHANGED_COLUMN() {
    return new DateColumn({
      key: 'changed',
      text: 'table.header.changed',
      sortable: true,
      weight: 2
    })
  }

  static VALID_COLUMN() {
    return new DropdownMultiselectColumn({
      key: 'valid',
      text: 'table.header.valid',
      items: [
        {value: true, name: 'valid.true'},
        {value: false, name: 'valid.false'}
      ],
      sortable: true,
      weight: 2
    })
  }

  static DELETE_COLUMN(configuration) {
    return new GenericColumn({
      key: '',
      text: 'table.header.delete',
      cell: new ButtonCell({
        callback: configuration.callback,
        icon: 'trash'
      }),
      weight: 1
    })
  }
}
