import Render from './Render.js'
import {html} from '@isceco/widget-library2/external/lit'
import Enum from '../Enum/Enum.js'

export default class PBKRender extends Render {
  render = () => {
    if (this.metadaten.PBK) {
      return this.renderPBK()
    } else {
      return html`
        <div class="group">
          <div class="header row">
            ${this.i18n.translate('label.pbk')}
            <div class="edit">
              <isceco-button icon="plus"
                             text="${this.i18n.translate('label.pbk.add')}"
                             variant="tertiary"
                             @click="${_ => this.addPBK()}"
              ></isceco-button>
            </div>
          </div>
        </div>
      `
    }
  }

  renderPBK = () => html`
    <div class="group">
      <div class="header row">
        ${this.i18n.translate('label.pbk')}
        <div class="edit">
          <isceco-button icon="x"
                         text="${this.i18n.translate('label.remove')}"
                         variant="tertiary"
                         @click="${_ => this.removePBK()}"
          ></isceco-button>
        </div>
      </div>
      <div class="section">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate('label.pbk.decision')}
        </div>
        ${this.text({key: 'PBK.Decision.Companynr', maxLength: 20, priority: true, required: true})}
        ${this.number({key: 'PBK.Decision.TargetWageTotalRes', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.WageCutTotalRes', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.ViolationRes', max: 99.9, digits: 1, priority: true, info: true})}
        ${this.select({key: 'PBK.Decision.SanctionRes', items: Enum.sanction(), priority: true, required: true})}
        ${this.number({key: 'PBK.Decision.PenaltyRes', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.CostRes', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.date({key: 'PBK.Decision.DateResolution', priority: true, required: true})}
        ${this.date({key: 'PBK.Decision.AppealReceiptDate', priority: true})}
        ${this.number({key: 'PBK.Decision.TargetWageTotalApp', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.WageCutTotalApp', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.ViolationApp', max: 99.9, digits: 1, priority: true, info: true})}
        ${this.date({key: 'PBK.Decision.DateAppealDec'})}
        ${this.select({key: 'PBK.Decision.AppealDecType', items: Enum.appeal()})}
        ${this.select({key: 'PBK.Decision.SanctionApp', items: Enum.sanction()})}
        ${this.number({key: 'PBK.Decision.PenaltyApp', max: 999999.99, digits: 2, priority: true, required: true, info: true})}
        ${this.number({key: 'PBK.Decision.CostApp', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.select({key: 'PBK.Decision.WageDiffPaied', items: Enum.wageDiff(), priority: true})}
        ${this.number({key: 'PBK.Decision.WageDiffPaiedAmount', max: 999999.99, digits: 2, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.WageDiffPaiedPerc', max: 99.9, digits: 1, priority: true, info: true})}
        ${this.checkbox({key: 'PBK.Decision.PenaltyPaied', priority: true})}
        ${this.number({key: 'PBK.Decision.VacationDays', max: 99, priority: true, info: true})}
        ${this.number({key: 'PBK.Decision.TargetWageEmployeeRes', max: 999999.99, digits: 2, info: true})}
        ${this.number({key: 'PBK.Decision.ActualWageEmployeeRes', max: 999999.99, digits: 2, info: true})}
        ${this.number({key: 'PBK.Decision.WageCutEmployeeRes', max: 999999.99, digits: 2, info: true})}
        ${this.number({key: 'PBK.Decision.TargetWageEmployeeApp', max: 999999.99, digits: 2, info: true})}
        ${this.number({key: 'PBK.Decision.ActualWageEmployeeApp', max: 999999.99, digits: 2, info: true})}
        ${this.number({key: 'PBK.Decision.WageCutEmployeeApp', max: 999999.99, digits: 2, info: true})}
        ${this.checkbox({key: 'PBK.Decision.Auskunftspflichtverletzung', priority: true, required: true})}
      </div>
      <div class="section">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate('label.pbk.result')}
        </div>
        ${this.select({key: 'PBK.Result.ControlResult', items: Enum.controlResult(), priority: true})}
        ${this.select({key: 'PBK.Result.NewPrioCompany', items: Enum.newPrio(), info: true})}
      </div>
    </div>
  `

  removePBK = () => {
    delete this.metadaten.PBK
    this.redraw()
  }

  addPBK = () => {
    this.metadaten.PBK = {
      Decision: {},
      Result: {}
    }
    this.redraw()
  }
}
