export default {
  de: {
    0: '0',
    1: '1',
    2: '2'
  },
  fr: {
    0: '0',
    1: '1',
    2: '2'
  },
  it: {
    0: '0',
    1: '1',
    2: '2'
  }
}
