import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/CheckboxFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import WebComponent from '../../WebComponent.js'
import ActiveRow from '../Dossier/ActiveRow.js'
import Dossierstatus from '../Dossier/Dossierstatus.js'
import DossierService from '../Dossier/DossierService.js'
import DossierDTO from '../Dossier/DossierDTO.js'
import DossierColumns from '../Dossier/DossierColumns.js'
import OrganisationService from '../Organisation/OrganisationService.js'
import Meldeart from '../Dossier/Meldeart.js'

export default class Empfangen extends WebComponent {

  constructor() {
    super()
    this.tableEvent = 'dossier-changed'
    this.zurueckweisenInputId = 'zurueckweisen-dialog-input'
  }

  get translationFile() {
    return './views/Empfangen/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.metadatenDialogId = 'metadaten-dialog'
    this.reload()
  }

  reload() {
    const organisationService = new OrganisationService()
    const dossierService = new DossierService()

    Promise.all([
      organisationService.list(),
      dossierService.getBackendListUrl('empfangen')
    ])
      .then(([json, url]) => {
        this.url = url
        this.organisation = json.map(entry => ({value: entry.code, name: entry.name}))
        this.render()
      })
      .catch(_ => {
        this.url = ''
        this.organisation = []
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('empfangen.titel')}"></isceco-title>

      <p>${this.i18n.translate('empfangen.beschreibung')}</p>

      <isceco-filter-group>
        <isceco-checkbox-filter label="${this.i18n.translate('table.header.verarbeitet')}"
                                key="verarbeitet"
        ></isceco-checkbox-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.dossiernummer')}"
                              key="code"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.meldeart')}"
                                   .items="${Meldeart.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="art"
        ></isceco-multiselect-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.sender')}"
                                   .items="${this.organisation}"
                                   key="sender"
        ></isceco-multiselect-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.datenfile')}"
                              key="metadaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.pdffile')}"
                              key="nutzdaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.sendedatum')}"
                            key="created"
        ></isceco-date-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.bearbeitetdatum')}"
                            key="changed"
        ></isceco-date-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.status')}"
                                   .items="${Dossierstatus.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="status"
        ></isceco-multiselect-filter>
      </isceco-filter-group>

      <isceco-list update-event="${this.tableEvent}"
                   id="flamfrontend-empfangene"
                   url=${this.url}
                   .i18n="${this.i18n}"
                   .rowVariant="${new ActiveRow()}"
                   .dtoMapper="${this.toDossier}"
                   .columns="${[
                     DossierColumns.VERARBEITET_COLUMN({callback: this.dossierVerarbeitet, disabled: this.verarbeitenDisabled}),
                     DossierColumns.CODE_COLUMN(),
                     DossierColumns.MELDEART_COLUMN(),
                     DossierColumns.SENDER_COLUMN(this.organisation),
                     DossierColumns.METADATEN_COLUMN({callback: this.openMetadatenDialog}),
                     DossierColumns.NUTZDATEN_COLUMN({callback: this.downloadNutzdatenFile}),
                     DossierColumns.SENDEDATUM_COLUMN(),
                     DossierColumns.BEARBEITETDATUM_COLUMN(),
                     DossierColumns.STATUS_COLUMN('status.archived'),
                     DossierColumns.ZURUECKWEISEN_COLUMN({callback: this.dossierZurueckweisen, disabled: this.zurueckweisenDisabled})
                   ]}"
                   .defaultFilters="${this.defaultFilters()}"
                   sort="changed"
      ></isceco-list>

      <isceco-dialog hidden id="zurueckweisen-dialog"
                     header="${this.i18n.translate('empfangen.zurueckweisen.popup.titel')}"
                     confirm-button="${this.i18n.translate('empfangen.zurueckweisen.popup.button.ok')}"
                     cancel-button="${this.i18n.translate('empfangen.zurueckweisen.popup.button.cancel')}"
                     @submit="${e => this.submitZurueckweisenDialog(e)}"
      >
        <isceco-textarea id="${this.zurueckweisenInputId}" rows="4" maxlength="255" required></isceco-textarea>
      </isceco-dialog>

      <isceco-dialog hidden
                     id="${this.metadatenDialogId}"
                     header="${this.i18n.translate('metadaten.dialog.title')}"
                     @submit="${e => e.target.hidden = true}"
      >
        <isceco-link id="metadaten-dialog-view"
                     text="${this.i18n.translate('metadaten.dialog.view')}"
                     @click="${_ => this.toDetail()}"
        ></isceco-link>
        </br></br>
        <isceco-link id="metadaten-dialog-download"
                     text="${this.i18n.translate('metadaten.dialog.download')}"
                     @click="${_ => this.downloadMetadatenFile()}"
        ></isceco-link>
      </isceco-dialog>
    `
  }

  defaultFilters = () => getNavigationParams().size > 0 ? null :
    {status: [Dossierstatus.STATUS_KEYS.HOCHGELADEN, Dossierstatus.STATUS_KEYS.HERUNTERGELADEN, Dossierstatus.STATUS_KEYS.NICHT_ABGEHOLT]}

  toDossier = row => new DossierDTO(row)

  dossierVerarbeitet = (checked, dossier) => {
    this.changeStatus({
      id: dossier.code,
      status: checked ? Dossierstatus.STATUS_KEYS.VERARBEITET : Dossierstatus.STATUS_KEYS.HERUNTERGELADEN
    })
  }

  verarbeitenDisabled = dossier => {
    return dossier.status !== Dossierstatus.STATUS_KEYS.HERUNTERGELADEN &&
      dossier.status !== Dossierstatus.STATUS_KEYS.VERARBEITET
  }

  zurueckweisenDisabled = dossier => {
    return dossier.status === Dossierstatus.STATUS_KEYS.ZURUECKGEWIESEN ||
      dossier.status === Dossierstatus.STATUS_KEYS.ARCHIVIERT
  }

  dossierZurueckweisen = dossier => {
    document.getElementById(this.zurueckweisenInputId).value = ''
    this.zurueckweisenId = dossier.code
    document.getElementById('zurueckweisen-dialog').hidden = false
  }

  submitZurueckweisenDialog = event => {
    const input = document.getElementById(this.zurueckweisenInputId)
    if (event.detail.confirmed && isEmpty(input.value)) {
      input.classList.add('error')
      return
    }

    event.target.hidden = true
    if (event.detail.confirmed) {
      this.changeStatus({
        id: this.zurueckweisenId,
        status: Dossierstatus.STATUS_KEYS.ZURUECKGEWIESEN,
        zurueckgewiesen_grund: input.value
      })
    }
  }

  openMetadatenDialog = dossier => {
    this.data = dossier
    this.querySelector(`#${this.metadatenDialogId}`).hidden = false
  }

  toDetail = () => {
    navigate({to: 'DatenfileDetail', params: {dossier: this.data.code}})
  }

  downloadMetadatenFile = () => {
    const dossierService = new DossierService()
    dossierService.download(this.data.code, this.data.metadaten, 'metadaten')
      .then(_ => this.changeStatusToHeruntergeladen(this.data))
      .finally(_ => {
        this.querySelector(`#${this.metadatenDialogId}`).hidden = true
      })
  }

  downloadNutzdatenFile = dossier => {
    const dossierService = new DossierService()
    dossierService.download(dossier.code, dossier.nutzdaten, 'nutzdaten')
      .then(_ => this.changeStatusToHeruntergeladen(dossier))
  }

  changeStatusToHeruntergeladen(dossier) {
    if (dossier.status === Dossierstatus.STATUS_KEYS.HOCHGELADEN || dossier.status === Dossierstatus.STATUS_KEYS.NICHT_ABGEHOLT) {
      this.changeStatus({
        id: dossier.code,
        status: Dossierstatus.STATUS_KEYS.HERUNTERGELADEN
      })
    }
  }

  changeStatus(object) {
    const dossierService = new DossierService()
    dossierService.update(object).then(_ => send(this.tableEvent))
  }
}

customElements.define('flam-empfangen', Empfangen)
