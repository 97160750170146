import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/CheckboxFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import {html, css} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Flam from '../MainView/Flam.js'
import ClickableRow from './ClickableRow.js'
import OrganisationColumns from './OrganisationColumns.js'
import OrganisationDTO from './OrganisationDTO.js'
import OrganisationService from './OrganisationService.js'

export default class Organisation extends WebComponent {

  constructor() {
    super()
    this.tableEvent = 'organisation-changed'
  }

  get translationFile() {
    return './views/Organisation/i18n.json'
  }

  get css() {
    return css`
      #flamfrontend-organisation {
        margin: var(--isceco-space-200) 0;
      }
      
      #organisation-erfassen {
        float: right;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    const organisationService = new OrganisationService()
    this.id = window.keycloak.tokenParsed.unitExtId
    organisationService.getBackendListUrl()
      .then(url => {
        this.url = url
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    const isAdmin = window.keycloak.hasRealmRole(Flam.ROLES().admin)
    const columns = [
      OrganisationColumns.KENNUNG_COLUMN(),
      OrganisationColumns.NAME_COLUMN(),
      OrganisationColumns.EMAIL_COLUMN(),
      OrganisationColumns.TELEFON_COLUMN()
    ]
    let aktivFilter = ''
    if (isAdmin) {
      aktivFilter = html`
        <isceco-checkbox-filter label="${this.i18n.translate('organisation.organisation.aktiv')}"
                                key="aktiv"
        ></isceco-checkbox-filter>
      `
      columns.push(OrganisationColumns.AKTIV_COLUMN())
      columns.push(OrganisationColumns.BEARBEITEN_COLUMN({
        callback: this.organisationBearbeiten
      }))
      columns.push(OrganisationColumns.LOESCHEN_COLUMN({
        callback: this.organisationLoeschen,
        disabled: this.loeschenDisabled
      }))
    }

    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('organisation.titel')}">
        ${this.getErfassenButtonTemplate(isAdmin)}
      </isceco-title>

      <p>${this.i18n.translate('organisation.beschreibung')}</p>

      <isceco-filter-group>
        <isceco-string-filter label="${this.i18n.translate('organisation.organisation.kennung')}"
                              key="code"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('organisation.organisation.name')}"
                              key="name"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('organisation.organisation.email')}"
                              key="email"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('organisation.organisation.telefon')}"
                              key="telefon"
                              minchars="1"
        ></isceco-string-filter>
        ${aktivFilter}
      </isceco-filter-group>

      <isceco-list update-event="${this.tableEvent}"
                   id="flamfrontend-organisation"
                   url="${this.url}"
                   .i18n="${this.i18n}"
                   .rowVariant="${new ClickableRow()}"
                   .rowurl="${organisation => this.organisationDetail(organisation)}"
                   .dtoMapper="${this.toOrganisation}"
                   .columns="${columns}"
                   sort="code"
      ></isceco-list>

      <isceco-dialog hidden id="organisation-bearbeiten-dialog"
                     confirm-button="${this.i18n.translate('organisation.bearbeiten.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('organisation.bearbeiten.dialog.button.cancel')}"
                     @submit="${e => this.submitBearbeitenDialog(e)}"
      >
        <isceco-form id="organisation-bearbeiten-form">
          <div slot="form-elements" class="form fullwidth">
            <isceco-checkbox id="organisation-bearbeiten-dialog-aktiv"
                             label="${this.i18n.translate('organisation.organisation.aktiv')}"
                             name="aktiv"
            ></isceco-checkbox>

            <isceco-text-input
              id="organisation-bearbeiten-dialog-code"
              label="${this.i18n.translate('organisation.organisation.kennung')}"
              type="text"
              maxlength="10"
              inline
              required
              name="code"
            ></isceco-text-input>

            <isceco-text-input
              id="organisation-bearbeiten-dialog-name"
              label="${this.i18n.translate('organisation.organisation.name')}"
              type="text"
              maxlength="50"
              inline
              required
              name="name"
            ></isceco-text-input>

            <isceco-text-input
              id="organisation-bearbeiten-dialog-email"
              label="${this.i18n.translate('organisation.organisation.email')}"
              type="email"
              pattern-error-text="${this.i18n.translate('organisation.error.email')}"
              maxlength="255"
              inline
              required
              name="email"
            ></isceco-text-input>

            <isceco-text-input
              id="organisation-bearbeiten-dialog-telefon"
              label="${this.i18n.translate('organisation.organisation.telefon')}"
              type="tel"
              maxlength="15"
              inline
              name="telefon"
            ></isceco-text-input>
          </div>
        </isceco-form>
      </isceco-dialog>

      <isceco-dialog hidden id="organisation-bestaetigen-dialog"
                     confirm-button="${this.i18n.translate('organisation.bestaetigen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('organisation.bestaetigen.dialog.button.cancel')}"
                     @submit="${e => this.submitBestaetigenDialog(e)}"
      >
      </isceco-dialog>
    `
  }

  getErfassenButtonTemplate(isAdmin) {
    if (isAdmin) {
      return html`
        <isceco-button text="${this.i18n.translate('organisation.organisation.neu')}"
                       variant="primary"
                       @click="${_ => this.organisationErfassen()}"
                       id="organisation-erfassen"
        ></isceco-button>
      `
    } else {
      return html``
    }
  }

  toOrganisation = row => new OrganisationDTO(row)

  organisationErfassen() {
    this.createNew = true
    this.openBearbeitenDialog()
  }

  organisationLoeschen = organisation => {
    this.openBestaetigenDialog(organisation)
  }

  loeschenDisabled = organisation => organisation.aktiv

  organisationBearbeiten = organisation => {
    this.bearbeitenOrganisationId = organisation.code
    this.createNew = false
    this.openBearbeitenDialog(organisation)
  }

  openBestaetigenDialog(organisation) {
    this.bearbeitenId = organisation.code
    const dialog = document.getElementById('organisation-bestaetigen-dialog')
    dialog.header = this.i18n.translate('organisation.bestaetigen.dialog.loeschen.titel', {code: organisation.code})
    dialog.description = this.i18n.translate('organisation.bestaetigen.dialog.loeschen.beschreibung')
    dialog.hidden = false
  }

  submitBestaetigenDialog = event => {
    if (event.detail.confirmed) {
      const organisationService = new OrganisationService()
      organisationService.delete(this.bearbeitenId)
        .then(_ => {
          this.showSuccess('loeschen', this.bearbeitenId)
          send(this.tableEvent)
        })
    }
    event.target.hidden = true
  }

  openBearbeitenDialog(organisation = {}) {
    const keys = ['aktiv', 'code', 'name', 'email', 'telefon']
    keys.forEach(id => {
      const input = document.getElementById(`organisation-bearbeiten-dialog-${id}`)
      input.value = organisation[id]
      input.className = ''
    })

    const dialog = document.getElementById('organisation-bearbeiten-dialog')
    if (this.createNew) {
      dialog.header = this.i18n.translate('organisation.bearbeiten.dialog.titel.neu')
    } else {
      dialog.header = this.i18n.translate('organisation.bearbeiten.dialog.titel.bearbeiten')
    }
    dialog.hidden = false
  }

  submitBearbeitenDialog = event => {
    if (event.detail.confirmed) {
      const form = this.querySelector('#organisation-bearbeiten-form')
      if (form.validate()) {
        this.bearbeitenOrganisation = form.getValues()
        if (this.createNew) {
          this.createNewOrganisation(event)
        } else {
          this.bearbeitenOrganisation.id = this.bearbeitenOrganisationId
          this.updateOrganisation(event)
        }
      }
    } else {
      event.target.hidden = true
    }
  }

  createNewOrganisation(event) {
    const organisationService = new OrganisationService()
    organisationService.create(this.bearbeitenOrganisation)
      .then(_ => {
        this.showSuccess('neu', this.bearbeitenOrganisation.code)
        send(this.tableEvent)
        event.target.hidden = true
      })
      .catch(_ => {
        event.target.hidden = true
      })
  }

  updateOrganisation(event) {
    const organisationService = new OrganisationService()
    organisationService.update(this.bearbeitenOrganisation)
      .then(_ => {
        this.showSuccess('bearbeiten', this.bearbeitenOrganisation.hasOwnProperty('code') ? this.bearbeitenOrganisation.code : this.bearbeitenOrganisation.id)
        send(this.tableEvent)
        event.target.hidden = true
      })
      .catch(_ => {
        event.target.hidden = true
      })
  }

  showSuccess(titleKey, code) {
    showAlert(
      this.i18n.translate(`organisation.success.titel.${titleKey}`),
      this.i18n.translate(`organisation.success.body.${titleKey}`, {code: code}),
      'success'
    )
  }

  organisationDetail = organisation => `OrganisationDetail/${organisation.code}`
}

customElements.define('flam-organisation', Organisation)
