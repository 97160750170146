import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Flam from '../MainView/Flam.js'
import UserColumns from '../User/UserColumns.js'
import UserDTO from '../User/UserDTO.js'
import UserService from '../Organisation/UserService.js'
import OrganisationService from '../Organisation/OrganisationService.js'


export default class Profil extends WebComponent {

  get translationFile() {
    return './views/Profil/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.changedSettings = {}

    this.id = window.keycloak.tokenParsed.unitExtId

    const organisationService = new OrganisationService()
    const userService = new UserService()
    Promise.all([organisationService.read(this.id), userService.getBackendListUrl(this.id)])
      .then(([organisationJson, userUrl]) => {
        this.organisation = organisationJson
        this.url = userUrl
        this.render()
      })
      .catch(_ => {
        this.organisation = {}
        this.url = ''
        this.render()
      })
  }

  getTemplate() {
    const isAdmin = window.keycloak.hasRealmRole(Flam.ROLES().admin)
    const darfNichtBearbeiten = !window.keycloak.hasRealmRole(Flam.ROLES().superuser) && !isAdmin

    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('profil.titel')}"></isceco-title>

      <p>${this.i18n.translate('profil.beschreibung')}</p>

      <isceco-form submit-button-text="${this.i18n.translate('profil.speichern')}"
                   @submit="${e => this.showConfirmDialog(e)}"
                   ?disabled="${darfNichtBearbeiten}"
                   id="profil-bearbeiten-form">
        <div slot="form-elements" class="form">
          <isceco-title size="medium" text="${this.i18n.translate('profil.user.titel')}"></isceco-title>
  
          <isceco-list id="profil-user"
                       url="${this.url}"
                       .i18n="${this.i18n}"
                       .dtoMapper="${this.toUser}"
                       .columns="${[
                         UserColumns.VORNAME_COLUMN(),
                         UserColumns.NAME_COLUMN(),
                         UserColumns.EMAIL_COLUMN(),
                         UserColumns.TELEFON_COLUMN()
                       ]}"
                       sort="name"
          ></isceco-list>
  
          <isceco-text-input id="profil-einstellungen-email"
                             label="${this.i18n.translate('profil.email')}"
                             value="${this.organisation.email}"
                             type="email"
                             pattern-error-text="${this.i18n.translate('profil.alert.email')}"
                             maxlength="255"
                             inline
                             required
                             ?disabled="${darfNichtBearbeiten}"
                             name="email"
                             @change="${e => this.setChanged(e)}"
          ></isceco-text-input>
  
          <isceco-text-input id="profil-einstellungen-telefon"
                             label="${this.i18n.translate('profil.telefon')}"
                             value="${this.organisation.telefon}"
                             type="tel"
                             maxlength="15"
                             inline
                             ?disabled="${darfNichtBearbeiten}"
                             name="telefon"
                             @change="${e => this.setChanged(e)}"
          ></isceco-text-input>
  
          ${this.getNotificationSettingsTemplate(isAdmin, darfNichtBearbeiten)}
        </div>
      </isceco-form>

      <isceco-dialog hidden id="profil-bestaetigen-dialog"
                     confirm-button="${this.i18n.translate('profil.bestaetigen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('profil.bestaetigen.dialog.button.cancel')}"
                     header="${this.i18n.translate('profil.bestaetigen.dialog.title')}"
                     @submit="${e => this.updateSettings(e)}"
      ></isceco-dialog>
    `
  }

  getNotificationSettingsTemplate(isAdmin, darfNichtBearbeiten) {
    if (isAdmin) {
      return html``
    } else {
      return html`
        <isceco-title size="medium" text="${this.i18n.translate('profil.einstellungen.titel')}"></isceco-title>

        <p>${this.i18n.translate('profil.einstellungen.beschreibung')}</p>

        <isceco-checkbox id="profil-einstellungen-notifikation_neu"
                         label="${this.i18n.translate('profil.einstellungen.neu')}"
                         ?value="${this.organisation.notifikation_neu}"
                         name="notifikation_neu"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-checkbox id="profil-einstellungen-notifikation_selber_nicht_heruntergeladen"
                         label="${this.i18n.translate('profil.einstellungen.selberNichtHeruntergeladen')}"
                         ?value="${this.organisation.notifikation_selber_nicht_heruntergeladen}"
                         name="notifikation_selber_nicht_heruntergeladen"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-checkbox id="profil-einstellungen-notifikation_nicht_heruntergeladen"
                         label="${this.i18n.translate('profil.einstellungen.nichtHeruntergeladen')}"
                         ?value="${this.organisation.notifikation_nicht_heruntergeladen}"
                         name="notifikation_nicht_heruntergeladen"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-checkbox id="profil-einstellungen-notifikation_zurueckgewiesen"
                         label="${this.i18n.translate('profil.einstellungen.zurueckgewiesen')}"
                         ?value="${this.organisation.notifikation_zurueckgewiesen}"
                         name="notifikation_zurueckgewiesen"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-checkbox id="profil-einstellungen-notifikation_heruntergeladen"
                         label="${this.i18n.translate('profil.einstellungen.heruntergeladen')}"
                         ?value="${this.organisation.notifikation_heruntergeladen}"
                         name="notifikation_heruntergeladen"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-checkbox id="profil-einstellungen-notifikation_bearbeitet"
                         label="${this.i18n.translate('profil.einstellungen.bearbeitet')}"
                         ?value="${this.organisation.notifikation_bearbeitet}"
                         name="notifikation_bearbeitet"
                         @change="${e => this.setChanged(e)}"
                         ?disabled="${darfNichtBearbeiten}"
        ></isceco-checkbox>

        <isceco-title size="medium" text="${this.i18n.translate('profil.sprache.titel')}"></isceco-title>

        <p>${this.i18n.translate('profil.sprache.beschreibung')}</p>

        <isceco-dropdown id="profil-einstellungen-sprache"
                         ?disabled="${darfNichtBearbeiten}"
                         name="sprache"
                         @change="${e => this.setChanged(e)}"
                         .items="${this.getLanguageOptions()}"
                         value="${this.organisation.sprache}"
        ></isceco-dropdown>
      `
    }
  }

  getLanguageOptions() {
    return window.iscecoWidgetLibrary.supportedLanguages
      .map(id => ({value: id, name: this.i18n.translate(`sprache.${id}`)}))
  }

  toUser = row => new UserDTO(row)

  setChanged = event => {
    event.target.classList.add('changed')
  }

  showConfirmDialog = event => {
    this.changedSettings = event.detail
    document.getElementById('profil-bestaetigen-dialog').hidden = false
  }

  updateSettings(event) {
    if (event.detail.confirmed) {
      this.changedSettings['id'] = this.id
      const organisationService = new OrganisationService()
      organisationService.update(this.changedSettings)
        .then(_ => {
          showAlert(
            this.i18n.translate('profil.speichern.alert.title'),
            this.i18n.translate('profil.speichern.alert.body', {name: this.organisation.name}),
            'success')
          this.cleanup()
          this.reload()
        })
        .catch(_ => event.target.hidden = true)
    }
    event.target.hidden = true
  }

  cleanup() {
    Object.keys(this.changedSettings)
      .forEach(key => document.getElementById(`profil-einstellungen-${key}`).classList.remove('changed'))
    this.changedSettings = {}
  }
}

customElements.define('flam-profil', Profil)
