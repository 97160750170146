import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import UserColumns from '../../User/UserColumns.js'
import UserDTO from '../../User/UserDTO.js'
import OrganisationService from '../OrganisationService.js'
import UserService from '../UserService.js'
import WebComponent from '../../../WebComponent.js'

export default class OrganisationDetail extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    let id = getNavigationId()
    if (!id) {
      id = window.keycloak.tokenParsed.unitExtId
    }

    const organisationService = new OrganisationService()
    const userService = new UserService()
    Promise.all([organisationService.read(id), userService.getBackendListUrl(id)])
      .then(([organisationJson, userUrl]) => {
        this.organisation = organisationJson
        this.url = userUrl
        this.render()
      })
      .catch(_ => {
        this.organisation = {}
        this.url = ''
        this.render()
      })
  }

  get translationFile() {
    return './views/Organisation/Detail/i18n.json'
  }

  getTemplate() {
    const tel = isEmpty(this.organisation.telefon) ?
      html`<p>${this.i18n.translate('organisation.detail.leer')}</p>` :
      html`<isceco-link text="${this.organisation.telefon}" url="tel:${this.organisation.telefon}"></isceco-link>`

    return html`
      <isceco-title id="page-title"
                    text="${this.i18n.translate('organisation.detail.titel', {
                      name: this.organisation.name
                    })}"
      ></isceco-title>

      <p>
        ${this.i18n.translate('organisation.detail.beschreibung', {
          name: this.organisation.name
        })}
      </p>

      <div class="form">
        <isceco-title size="medium" text="${this.i18n.translate('organisation.detail.code')}"></isceco-title>
        <p>${this.organisation.code}</p>

        <isceco-title size="medium" text="${this.i18n.translate('organisation.detail.email')}"></isceco-title>
        <isceco-link text="${this.organisation.email}" url="mailto:${this.organisation.email}"></isceco-link>

        <isceco-title size="medium" text="${this.i18n.translate('organisation.detail.telefon')}"></isceco-title>
        ${tel}

        <isceco-title size="medium" text="${this.i18n.translate('organisation.detail.user')}"></isceco-title>
        <isceco-list id="organisation-user"
                      url="${this.url}"
                      .i18n="${this.i18n}"
                      .dtoMapper="${this.toUser}"
                      .columns="${[
                        UserColumns.VORNAME_COLUMN(),
                        UserColumns.NAME_COLUMN(),
                        UserColumns.EMAIL_COLUMN(),
                        UserColumns.TELEFON_COLUMN()
                      ]}"
                     sort="name"
        ></isceco-list>
      </div>
    `
  }

  toUser = row => new UserDTO(row)
}

customElements.define('flam-user', OrganisationDetail)
