export default {
  de: {
    1: 'Nicht darauf eingetreten',
    2: 'abgelehnt',
    3: 'teilweise gutgeheissen',
    4: 'vollständig gutgeheissen'
  },
  fr: {
    1: 'non entré en matière',
    2: 'refusé',
    3: 'partiellement approuvé',
    4: 'entièrement approuvé'
  },
  it: {
    1: 'non entrata nel merito',
    2: 'respinto',
    3: 'parzialmente accolto',
    4: 'interamente accolto'
  }
}
