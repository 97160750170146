import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'

export default class NotAuthorized extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <h1>You are not authorized! Please contact an ISCeco Administrator!</h1></br>
        <img src="https://media.giphy.com/media/nlcJ4MkoG3Tri/giphy.gif" alt="not found"/>
      </div>
    `
  }

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }
}

customElements.define('not-authorized', NotAuthorized)
