import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Flam from '../MainView/Flam.js'
import StorageService from '../ObjectStorage/StorageService.js'

export default class About extends WebComponent {

  static get FILES() {
    return {
      JSON_SCHEMA: 'metadaten_schema.json',
      EXCEL_SCHEMA: {
        de: 'FlaM-Schnittstelle Dokumentation_v1.0.4_DE.xlsx',
        fr: 'FlaM-Schnittstelle Dokumentation_v1.0.4_FR.xlsx',
        it: 'FlaM-Schnittstelle Dokumentation_v1.0.4_IT.xlsx',
      },
      HANDBOOK: {
        de: 'Handbuch_FlaM_Plattform_DE.docx',
        fr: 'Handbuch_FlaM_Plattform_FR.docx',
        it: 'Handbuch_FlaM_Plattform_IT.docx',
      },
      VIDEO_URL: {
        de: 'https://youtu.be/GYuWK60CuVY',
        fr: 'https://youtu.be/MQMULUWiZ1k'
      }
    }
  }

  get translationFile() {
    return './views/About/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    const storageService = new StorageService()
    this.isAdmin = window.keycloak.hasRealmRole(Flam.ROLES().admin)
    let promise
    if (this.isAdmin) {
      promise = storageService.storage()
        .then(info => {
          this.totalStorage = info.size
          this.totalFiles = info.count
          return storageService.getBackendListUrl()
        })
        .catch(_ => storageService.getBackendListUrl())
    } else {
      promise = storageService.getBackendListUrl()
    }
    promise
      .then(storageUrl => {
        this.storageUrl = storageUrl
        this.render()
      })
      .catch(_ => {
        this.storageUrl = '#'
        this.render()
      })
  }

  getTemplate() {
    const storageInfo = this.isAdmin ?
      html`
        <isceco-title size="medium" text="${this.i18n.translate('about.storage.title')}"></isceco-title>
        <p>
          ${this.i18n.translate('about.storage.description', {
            size: formatFileSize(this.totalStorage),
            count: this.totalFiles
          })}
        </p>
      ` : ''
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('about.titel')}"></isceco-title>

      <p>
        ${this.i18n.translate('about.description')}
      </p>

      <p>
        <isceco-link text="${this.i18n.translate('about.contact')}"
                     url="mailto:flam-plattform@seco.admin.ch"
        ></isceco-link>
      </p>
      <p>
        <isceco-link text="${this.i18n.translate('docs.jsonschema')}"
                     @click="${_ => this.downloadFile(About.FILES.JSON_SCHEMA)}"
        ></isceco-link>
      </p>
      <p>
        ${this.renderExcelSchema()}
      </p>
      <p>
        ${this.renderHandbook()}
      </p>
      <p>
        ${this.renderVideo()}
      </p>
      ${storageInfo}
    `
  }

  renderExcelSchema = () => {
    const excelSchemaFile = About.FILES.EXCEL_SCHEMA[getLanguage()]
    return html`
      <isceco-link text="${this.i18n.translate('docs.excelschema')}"
                   @click="${_ => this.downloadFile(excelSchemaFile)}"
      ></isceco-link>
    `
  }

  renderHandbook = () => {
    const handbookFile = About.FILES.HANDBOOK[getLanguage()]
    return html`
      <isceco-link text="${this.i18n.translate('docs.handbook')}"
                   @click="${_ => this.downloadFile(handbookFile)}"
      ></isceco-link>
    `
  }

  renderVideo = () => {
    if (getLanguage() === 'it') {
      return html`
        ${this.i18n.translate('docs.video')}
        (
        <isceco-link text="D" url="${About.FILES.VIDEO_URL.de}" target="_blank"></isceco-link>
        /
        <isceco-link text="F" url="${About.FILES.VIDEO_URL.fr}" target="_blank"></isceco-link>
        )
      `
    } else {
      const videoURL = About.FILES.VIDEO_URL[getLanguage()]
      return html`
        <isceco-link text="${this.i18n.translate('docs.video')}"
                     url="${videoURL}"
                     target="_blank"
        ></isceco-link>
      `
    }
  }

  downloadFile(name) {
    const storageService = new StorageService()
    storageService.download(name)
  }
}

customElements.define('flam-about', About)
