import BaseService from '../../BaseService.js'

export default class StorageService extends BaseService {

  constructor() {
    super('docs')
  }

  storage() {
    return this.list('storage')
  }

  async download(filename) {
    return this.read('static', filename)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const download = document.createElement('a')
        download.href = url
        download.download = shortenFilename(filename)
        document.body.appendChild(download)
        download.click()
        download.remove()
        window.URL.revokeObjectURL(url)
      })
  }
}
