import CaseForm from './CaseForm.js'
import ControlType from './ControlType.js'
import Canton from './Canton.js'
import Country from './Country.js'
import Language from './Language.js'
import Gender from './Gender'
import Branch from './Branch'
import NotificationType from './NotificationType'
import OccupationAdmission from './OccupationAdmission'
import DecreeStatus from './DecreeStatus'
import IdType from './IdType'
import TimeControl from './TimeControl'
import OverTime from './OverTime'
import Sanction from './Sanction'
import Appeal from './Appeal'
import WageDiff from './WageDiff'
import ControlResult from './ControlResult'
import NewPrio from './NewPrio'
import AdditionalPaycheck from './AdditionalPaycheck'
import MeldeArt from './MeldeArt'

export default class Enum {

  static controlType() {
    return Enum.ITEMS(true, ControlType)
  }

  static caseForm() {
    return Enum.ITEMS(true, CaseForm)
  }

  static canton() {
    return Enum.ITEMS(false, Canton)
  }

  static country() {
    return Enum.ITEMS(true, Country)
  }

  static language() {
    return Enum.ITEMS(false, Language)
  }

  static gender() {
    return Enum.ITEMS(false, Gender)
  }

  static branch() {
    return Enum.ITEMS(true, Branch)
  }

  static notificationType() {
    return Enum.ITEMS(false, NotificationType)
  }

  static occupationAdmission() {
    return Enum.ITEMS(true, OccupationAdmission)
  }

  static decreeStatus() {
    return Enum.ITEMS(false, DecreeStatus)
  }

  static idType() {
    return Enum.ITEMS(true, IdType)
  }

  static timeControl() {
    return Enum.ITEMS(false, TimeControl)
  }

  static overTime() {
    return Enum.ITEMS(true, OverTime)
  }

  static sanction() {
    return Enum.ITEMS(true, Sanction)
  }

  static appeal() {
    return Enum.ITEMS(true, Appeal)
  }

  static wageDiff() {
    return Enum.ITEMS(true, WageDiff)
  }

  static controlResult() {
    return Enum.ITEMS(true, ControlResult)
  }

  static newPrio() {
    return Enum.ITEMS(true, NewPrio)
  }

  static additionalPaycheck() {
    return Enum.ITEMS(true, AdditionalPaycheck)
  }

  static meldeArt() {
    return Enum.ITEMS(true, MeldeArt)
  }

  static ITEMS(isInt, translations) {
    return Object.entries(translations[getLanguage()])
      .map(([key, text]) => ({value: isInt ? parseInt(key) : key, name: text}))
  }
}
