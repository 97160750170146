export default class Meldeart {
  // @alican Object freeze und als const
  static get ART_KEYS() {
    return {
      MELDEVERSTOSS: 3,
      VERSTOSS_AUSKUNFTSPFLICHT: 4,
      KONTROLLBERICHT: 5,
      GAV_VERSTOSS: 6,
      RUECKMELDUNG_KV: 7,
      VERSTOSS_DOKUMENTATIONSPFLICHT: 8,
      RUECKMELDUNG_AN_PK: 9
    }
  }

  static get OPTIONS() {
    return Object.values(Meldeart.ART_KEYS).map(id => ({
      value: id,
      name: `art.${id}`
    }))
  }

  static get OPTIONS_STR() {
    return Object.values(Meldeart.ART_KEYS).map(id => ({
      value: `${id}`,
      name: `art.${id}`
    }))
  }
}
