export default {
  de: {
    1: 'nein',
    2: 'teilweise',
    3: 'vollständig'
  },
  fr: {
    1: 'non',
    2: 'partiel',
    3: 'complet'
  },
  it: {
    1: 'no',
    2: 'in parte',
    3: 'integralmente'
  }
}
