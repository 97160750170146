export default {
  de: {
    1: 'Kontrolle am Ort - angetroffen - ohne Verdacht',
    2: 'Kontrolle am Ort - angetroffen - mit Verdacht',
    3: 'Kontrolle am Ort - nicht angetroffen',
    4: 'Schreibtisch-Kontrolle',
    5: 'Polizei-Kontrolle'
  },
  fr: {
    1: 'contrôles sur place - rencontré - sans soupçon',
    2: 'contrôles sur place - rencontré - avec soupçon',
    3: 'contrôle sur place - pas rencontré',
    4: 'contrôles sur pièces',
    5: 'contrôles de police'
  },
  it: {
    1: 'controllo sul posto - incontrato - senza sospetto',
    2: 'controllo sul posto - incontrato - con sospetto',
    3: 'controllo sul posto - non incontrato',
    4: 'controllo su documenti',
    5: 'controllo di polizia'
  }
}
