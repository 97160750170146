import BaseService from '../../BaseService.js'

export default class DossierService extends BaseService {
  constructor() {
    super('dossier')
  }

  async download(id, filename, subResource = '') {
    return this.read(id, subResource)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const download = document.createElement('a')
        download.href = url
        download.download = shortenFilename(filename)
        document.body.appendChild(download)
        download.click()
        download.remove()
        window.URL.revokeObjectURL(url)
      })
  }
}
