export default class UserDTO {

  constructor(json) {
    if (json) {
      this.json = json
      this.vorname = json.vorname
      this.name = json.name
      this.email = json.email
      this.telefon = json.telefon
    } else {
      this.json = {}
    }
  }
}
