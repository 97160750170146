export default {
  de: {
    D: 'Deutsch',
    E: 'Englisch',
    F: 'Französisch',
    I: 'Italienisch',
    R: 'Rätoromanisch'
  },
  fr: {
    D: 'allemand',
    E: 'anglais',
    F: 'français',
    I: 'italien',
    R: 'romanche'
  },
  it: {
    D: 'tedesco',
    E: 'inglese',
    F: 'francese',
    I: 'italiano',
    R: 'romancio'
  }
}
