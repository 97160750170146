export default class OrganisationDTO {

  constructor(json) {
    if (json) {
      this.json = json
      this.code = json.code
      this.name = json.name
      this.email = json.email
      this.telefon = json.telefon
      this.notifikation_neu = json.notifikation_neu
      this.notifikation_nicht_heruntergeladen = json.notifikation_nicht_heruntergeladen
      this.notifikation_zurueckgewiesen = json.notifikation_zurueckgewiesen
      this.notifikation_heruntergeladen = json.notifikation_heruntergeladen
      this.notifikation_bearbeitet = json.notifikation_bearbeitet
      this.aktiv = json.aktiv
    } else {
      this.json = {}
    }
  }
}
