import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import DossierService from '../Dossier/DossierService.js'
import Meldeart from '../Dossier/Meldeart.js'
import StorageService from '../ObjectStorage/StorageService.js'
import OrganisationService from '../Organisation/OrganisationService.js'
import MetadatenService from '../Datenfile/MetadatenService'
import DatenfileColumns from '../Datenfile/DatenfileColumns'
import MetadatenSendenRowVariant from './MetadatenSendenRowVariant'

export default class Senden extends WebComponent {

  constructor() {
    super()
    this.maxFileSize = 150e6
    this.nutzdatenTypes = 'application/pdf'
    this.metadatenTypes = 'application/json'
  }

  get translationFile() {
    return './views/Senden/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
    this.empfaenger = []
    this.organisation = {}
    this.url = ''
    this.dossierValues = {}
    this.metadatenDialogId = 'metadaten-dialog'
    this.metadatenSelectDialogId = 'metadaten-select-dialog'
  }

  reload() {
    const organisationService = new OrganisationService()
    const storageSerivce = new StorageService()
    const metadatenService = new MetadatenService()
    Promise.all([
      organisationService.list(),
      storageSerivce.getBackendListUrl(),
      organisationService.read(window.keycloak.tokenParsed.unitExtId),
      metadatenService.getBackendListUrl()
    ])
      .then(([empfaengerJson, backendUrl, organisationJson, metadatenUrl]) => {
        this.url = backendUrl
        this.empfaenger = empfaengerJson.map(entry => ({id: entry.code, text: entry.name}))
        this.organisation = organisationJson
        this.metadatenUrl = metadatenUrl
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    const darfNichtBearbeiten = !this.organisation.aktiv
    const metadatenColumns = [
      DatenfileColumns.NAME_COLUMN({callback: this.selectMetadaten}),
      DatenfileColumns.CHANGED_COLUMN(),
      DatenfileColumns.VALID_COLUMN()
    ]
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('senden.titel')}"></isceco-title>

      <p>${this.i18n.translate('senden.beschreibung')}</p>

      <isceco-form submit-button-text="${this.i18n.translate('senden.versenden.button')}"
                   @submit="${e => darfNichtBearbeiten ? false : this.openDialog(e)}"
                   ?disabled="${darfNichtBearbeiten}"
                   id="dossier-anlegen">
        <div slot="form-elements" class="form">
          <isceco-dropdown id="dossier-empfaenger"
                           label="${this.i18n.translate('senden.empfaenger')}"
                           placeholder="${this.i18n.translate('senden.dropdown.leer')}"
                           .items="${this.getEmpfaengerOptions()}"
                           required
                           name="empfaenger"
                           ?disabled="${darfNichtBearbeiten}"
          ></isceco-dropdown>

          <isceco-dropdown id="dossier-meldeart"
                           label="${this.i18n.translate('senden.meldeart')}"
                           placeholder="${this.i18n.translate('senden.dropdown.leer')}"
                           .items="${Meldeart.OPTIONS_STR}"
                           .i18n="${this.i18n}"
                           required
                           name="meldeart"
                           ?disabled="${darfNichtBearbeiten}"
                           @change="${e => this.checkMeldeart(e)}"
          ></isceco-dropdown>

          <isceco-text-input id="dossier-referenz"
                             label="${this.i18n.translate('senden.referenz')}"
                             pattern="[0-9]{4}\\.[0-9]{8}(-[0-9]{2})?"
                             pattern-error-text="${this.i18n.translate('senden.error.referenz.format')}"
                             type="text"
                             name="referenz"
                             ?disabled="${darfNichtBearbeiten}"
          ></isceco-text-input>

          <isceco-file-input id="dossier-metadaten"
                             url="${this.url}"
                             label="${this.i18n.translate('senden.datenfile')}"
                             accept="${this.metadatenTypes}"
                             maxsize="${this.maxFileSize}"
                             required
                             name="metadaten"
                             ?disabled="${darfNichtBearbeiten}"
                             @click="${this.openMetadatenDialog}"
                             @change="${this.updateMetadatenFromInput}"
          ></isceco-file-input>

          <isceco-file-input id="dossier-nutzdaten"
                             url="${this.url}"
                             label="${this.i18n.translate('senden.pdffile')}"
                             accept="${this.nutzdatenTypes}"
                             maxsize="${this.maxFileSize}"
                             required
                             name="nutzdaten"
                             ?disabled="${darfNichtBearbeiten}"
          ></isceco-file-input>
        </div>
      </isceco-form>

      <isceco-dialog hidden id="dossier-dialog"
                     header="${this.i18n.translate('senden.versenden.dialog.titel')}"
                     confirm-button="${this.i18n.translate('senden.versenden.dialog.bestaetigen')}"
                     cancel-button="${this.i18n.translate('senden.versenden.dialog.abbrechen')}"
                     @submit="${e => this.submitDialog(e)}"
      >
      </isceco-dialog>

      <isceco-dialog hidden
                     id="${this.metadatenDialogId}"
                     header="${this.i18n.translate('senden.datenfile.dialog.title')}"
                     @submit="${this.closeMetadatenDialog}"
      >
        <isceco-link id="metadaten-dialog-select"
                     text="${this.i18n.translate('senden.datenfile.dialog.select')}"
                     @click="${this.openSelectMetadatenDialog}"
        ></isceco-link>
        </br></br>
        <isceco-link id="metadaten-dialog-upload"
                     text="${this.i18n.translate('senden.datenfile.dialog.upload')}"
                     @click="${_ => this.uploadDatenfile()}"
        ></isceco-link>
      </isceco-dialog>

      <isceco-dialog hidden
                     id="${this.metadatenSelectDialogId}"
                     header="${this.i18n.translate('senden.datenfile.dialog.select')}"
                     size="large"
                     @submit="${this.closeSelectMetadatenDialog}"
      >
        <isceco-list url="${this.metadatenUrl}"
                     .i18n="${this.i18n}"
                     .columns="${metadatenColumns}"
                     sort="changed"
                     .rowVariant="${new MetadatenSendenRowVariant()}"
                     @rowclick="${this.selectMetadaten}"
        ></isceco-list>
      </isceco-dialog>
    `
  }

  openSelectMetadatenDialog = () => {
    this.querySelector(`#${this.metadatenSelectDialogId}`).hidden = false
  }

  closeSelectMetadatenDialog = () => {
    this.querySelector(`#${this.metadatenSelectDialogId}`).hidden = true
  }

  selectMetadaten = rowOrEvent => {
    const dto = rowOrEvent.detail ? rowOrEvent.detail.row : rowOrEvent
    if (dto.valid) {
      this.selectedMetadatenId = dto.id
      this.dialogOpen = false
      const input = this.querySelector('#dossier-metadaten')
      input.value = dto.name
      input.fileName = dto.name
      input.file = dto.name
      input.fileInvalid = false
      input.message = ''
      input.classList.remove('error')
      input.requestUpdate()
      this.querySelector(`#${this.metadatenSelectDialogId}`).hidden = true
      this.querySelector(`#${this.metadatenDialogId}`).hidden = true
    }
  }

  openMetadatenDialog = event => {
    if (!this.dialogOpen) {
      event.preventDefault()
    }
    this.dialogOpen = true
    this.metadatenInput = event.target
    this.querySelector(`#${this.metadatenDialogId}`).hidden = false
  }

  updateMetadatenFromInput = () => {
    this.selectedMetadatenId = null
    this.closeMetadatenDialog()
  }

  closeMetadatenDialog = () => {
    this.dialogOpen = false
    this.querySelector(`#${this.metadatenDialogId}`).hidden = true
  }

  uploadDatenfile = () => {
    this.metadatenInput._selectFile()
  }

  getEmpfaengerOptions = () => this.empfaenger
    .filter(empf => empf.id !== window.keycloak.tokenParsed.unitExtId)
    .map(empf => ({value: empf.id, name: empf.text}))

  submitDialog = event => {
    event.target.hidden = true

    if (event.detail.confirmed) {
      const dossierService = new DossierService()
      dossierService.create(this.dossierValues)
        .then(json => {
          showAlert(
            this.i18n.translate('senden.alert.success'),
            this.i18n.translate('senden.alert.success.message', {
              code: json.code,
              empfaenger: this.getSelectedEmpfaenger()
            }),
            'success')
          navigate({to: '#'})
        })
    }
  }

  openDialog = event => {
    event.preventDefault()
    this.dossierValues = event.detail
    if (this.selectedMetadatenId !== null && this.selectedMetadatenId !== undefined) {
      this.dossierValues.metadatenId = this.selectedMetadatenId
    }

    const dialog = document.getElementById('dossier-dialog')
    dialog.description = this.i18n.translate('senden.versenden.dialog.beschreibung', {empfaenger: this.getSelectedEmpfaenger()})
    dialog.hidden = false
  }

  getSelectedEmpfaenger = () => {
    const empfaengerElement = document.getElementById('dossier-empfaenger')
    return empfaengerElement.valueText
  }

  checkMeldeart = event => {
    const referenz = this.getFormElement('referenz')
    const metadaten = this.getFormElement('metadaten')

    const intValue = parseInt(event.detail.value)
    if (intValue === Meldeart.ART_KEYS.RUECKMELDUNG_AN_PK || intValue === Meldeart.ART_KEYS.RUECKMELDUNG_KV) {
      referenz.required = true
      metadaten.required = false
      metadaten.validate()
    } else {
      referenz.required = false
      metadaten.required = true
      referenz.validate()
    }
  }

  getFormElement = key => document.getElementById(`dossier-${key}`)
}

customElements.define('flam-senden', Senden)
