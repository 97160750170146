export default class Navigation {

  static get NAVIGATION_HAPPEND() {
    return 'flam-navigation-happened'
  }

  constructor() {
    window.onhashchange = _ => window.send(Navigation.NAVIGATION_HAPPEND, getNavigationHash())
  }
}
const nav = new Navigation()
