export default {
  de: {
    1: 'Arbeitsplatzkontrolle (Baustellenkontrolle) > entspricht Entsendekontrolle',
    2: 'Selbstständige',
    3: 'Schweizer Betriebe',
    4: 'Personalverleih'
  },
  fr: {
    1: 'contrôles sur le lieu de travail (contrôle de chantiers)',
    2: 'indépendants',
    3: 'entreprises suisses',
    4: 'location de services'
  },
  it: {
    1: 'controllo sul posto di lavoro (controllo sul cantiere) > controllo del distacco',
    2: 'indipendenti',
    3: 'imprese svizzere',
    4: 'fornitura di personale a prestito'
  }
}
