export default {
  de: {
    1: 'Keine',
    2: 'Verweis',
    3: 'Konventionalstrafe',
    4: 'Selbstständig',
    5: 'Selbstständigkeit nicht anerkannt',
    6: 'Scheinselbstständig',
    7: 'Einstellung'
  },
  fr: {
    1: 'aucune',
    2: 'avertissement',
    3: 'peine conventionnelle',
    4: 'indépendant',
    5: 'statut d\'indépendant non reconnu',
    6: 'faux travail indépendant',
    7: 'classement'
  },
  it: {
    1: 'nessuna',
    2: 'ammonimento',
    3: 'pena convenzionale',
    4: 'indipendente',
    5: 'indipendenza non riconosciuta',
    6: 'indipendenza fittizia',
    7: 'archiviazione'
  }
}
