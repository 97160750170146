export default {
  de: {
    m: 'männlich',
    w: 'weiblich'
  },
  fr: {
    m: 'masculin',
    w: 'féminin'
  },
  it: {
    m: 'maschile',
    w: 'femminile'
  }
}
