import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Navigation from '../Navigation/Navigation.js'
import Dossier from '../Dossier/Dossier.js'
import ViewFilter from '../Navigation/ViewFilter.js'
import Profil from '../Profil/Profil.js'
import NoContent from '../NoContent/NoContent.js'
import NotAuthorized from '../NotAuthorized/NotAuthorized.js'
import Landing from '../Landing/Landing.js'
import OrganisationDetail from '../Organisation/Detail/OrganisationDetail.js'
import Organisation from '../Organisation/Organisation.js'
import Gesendet from '../Gesendet/Gesendet.js'
import Empfangen from '../Empfangen/Empfangen.js'
import Senden from '../Senden/Senden.js'
import About from '../About/About.js'
import Datenfile from '../Datenfile/Datenfile.js'
import DatenfileDetail from '../Datenfile/Detail/DatenfileDetail.js'

export default class MainView extends WebComponent {

  constructor() {
    super()
    this.views = {
      'About': About,
      'Dossier': Dossier,
      'Empfangen': Empfangen,
      'Gesendet': Gesendet,
      'Landing': Landing,
      'Organisation': Organisation,
      'OrganisationDetail': OrganisationDetail,
      'Profil': Profil,
      'Senden': Senden,
      'Datenfile': Datenfile,
      'DatenfileDetail': DatenfileDetail,
      '': Landing
    }
  }

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null
    this.navigationListener = e => {
      this.loadView(e.detail)
    }

    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.loadView(getNavigationHash())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  loadView(linkName) {
    let newChild = new NotAuthorized()
    if (new ViewFilter().viewAllowed(linkName)) {
      if (this.views.hasOwnProperty(linkName)) {
        newChild = new this.views[linkName]()
      } else {
        newChild = new NoContent()
      }
    }

    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }
    this.oldChild = newChild
  }

  getTemplate() {
    return html`
      flam-main-view
    `
  }
}

customElements.define('flam-main-view', MainView)
