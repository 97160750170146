export default {
  de: {
    I: 'Einzelmeldung',
    N: 'Sammelmeldung'
  },
  fr: {
    I: 'annonce individuelle',
    N: 'annonce collective'
  },
  it: {
    I: 'notifica individuale',
    N: 'notifica collettiva'
  }
}
